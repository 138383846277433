const allowedSpaces = [
  'first-space',
  'test-space-1',
  'clinton',
  'tipico5g',
  'tipico5g-staging',
  'tipicogames-stg',
  'cloudbet-staging',
  'supersport-staging',
  'reevoaggr-staging',
  'sisal-it-staging',
  '1x2network-staging'
];
const currentSpace = localStorage.getItem('zq-space-name');

const isDisabledInstantWins = allowedSpaces.includes(currentSpace) ? '' : 'disabled';

export default {
  _name: 'CSidebarNav',
  _children: [
    {
      _name: 'CSidebarNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'dashboard',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Achievements',
      to: '/achievements',
      icon: 'jorneys',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Missions',
      to: '/missions',
      icon: 'rocket',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Competitions',
      to: '/competitions',
      icon: 'competitions',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'InstantWins',
      to: '/instant-wins',
      icon: 'products',
      _attrs: { class: `c-sidebar-nav-item ${ isDisabledInstantWins }` }
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Rewards',
      to: '/rewards',
      icon: 'jorneys',
      _attrs: { class: 'c-sidebar-nav-item' }
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Awards',
      to: '/awards',
      icon: 'jorneys',
      _attrs: { class: 'c-sidebar-nav-item' }
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Members',
      to: '/members',
      icon: 'members',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Products',
      to: '/products',
      icon: 'products',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'CMS',
      to: '/cms',
      icon: 'web-assets',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Messaging',
      to: '/inbox',
      icon: 'events',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Events',
      to: '/events',
      icon: 'insights',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Settings',
      to: '/settings',
      icon: 'settings',
      _attrs: { class: 'c-sidebar-nav-item' },
    },
    // {
    //     _name: 'CSidebarNavItem',
    //     name: 'Helpdesk',
    //     to: 'https://ziqni.myfreshworks.com/login/auth/1643103936268?client_id=78368727158800390&redirect_uri=https%3A%2F%2Fhelp.ziqni.com%2Ffreshid%2Fcustomer_authorize_callback',
    //     icon: 'settings',
    //     _attrs: {class: 'c-sidebar-nav-item'},
    // },
  ],
};
