<template>
  <div class="d-flex flex-column w-100 pl-3 pr-3">
    <div class="w-100 d-flex align-items-center justify-content-end">
      <CButton
        @click="addFromTemplate"
        class="ml-2 zq--custom-button"
        :color="colorBtn('secondary')"
      >
        {{ 'Add from template' }}
      </CButton>
      <CButton
        @click="createReward"
        class="ml-3 zq--responsive-button__common"
        :color="colorBtn('danger')"
      >
        {{ 'Create' }}
      </CButton>
    </div>
    <div class="flex-column w-100 p-0">
      <table class="reward-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Reward Value</th>
          <th>Reward Rank</th>
          <th>Reward Type</th>
          <th>Expires</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody v-if="!selectedRewards.length">
        <tr>
          <td colspan="7" class="text-center"></td>
        </tr>
        <tr>
          <td colspan="7" class="text-center"></td>
        </tr>
        <tr>
          <td colspan="7" class="text-center"></td>
        </tr>
        <tr>
          <td colspan="7" class="text-center no-items">No items
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
              <path fill="red"
                    d="M425.706 86.294A240 240 0 0 0 86.294 425.705A240 240 0 0 0 425.706 86.294M256 48a207.1 207.1 0 0 1 135.528 50.345L98.345 391.528A207.1 207.1 0 0 1 48 256c0-114.691 93.309-208 208-208m0 416a207.08 207.08 0 0 1-134.986-49.887l293.1-293.1A207.08 207.08 0 0 1 464 256c0 114.691-93.309 208-208 208"/>
            </svg>
          </td>
        </tr>
        <tr>
          <td colspan="7" class="text-center"></td>
        </tr>
        <tr>
          <td colspan="7" class="text-center"></td>
        </tr>
        <tr>
          <td colspan="7" class="text-center"></td>
        </tr>
        </tbody>
        <tbody v-else v-for="(reward, index) in selectedRewards" :key="index">
        <tr :key="index">
          <td :title="reward.name" class="ellipsis">{{ reward.name }}</td>
          <td :title="reward.rewardValue" class="ellipsis">{{ reward.rewardValue }}</td>
          <td :title="reward.rewardRank" class="ellipsis">{{ reward.rewardRank }}</td>
          <td :title="reward.rewardType.key" class="ellipsis">{{ reward.rewardType.key }}</td>
          <td :title="reward.pointInTime" class="ellipsis">{{ reward.pointInTime }}</td>
          <td class="fixed-column-end">
            <div class="zq--table-actions-wrap">
              <ul class="m-0 p-0">
                <CDropdown
                  inNav
                  class="c-header-nav-items custom-dropbox"
                  placement="left"
                  add-menu-classes="p-0"
                >
                  <template #toggler>
                    <CButton class="zq--table-actions--single" @click="handleToggle(reward.id)">
                      <ClIcon cilIcon="cil-options" iconTypeProp="cil" imgIcon="actions-more"/>
                    </CButton>
                  </template>
                  <CDropdownItem class="p-0">
                    <CButton
                      pill
                      color="dark"
                      class="action-custom-button"
                      variant="ghost"
                      @click="showPreview(reward)"
                    >
                      {{ $t('buttons.view') }}
                    </CButton>
                  </CDropdownItem>
                  <CDropdownItem class="p-0">
                    <CButton
                      pill
                      color="dark"
                      class="action-custom-button"
                      variant="ghost"
                      @click="showEdit(reward, index)"
                    >
                      {{ $t('buttons.edit') }}
                    </CButton>
                  </CDropdownItem>
                  <CDropdownItem class="p-0">
                    <CButton
                      pill
                      color="dark"
                      :disabled="false"
                      class="action-custom-button"
                      variant="ghost"
                      @click="
                              deleteModal = true;
                              selectedId = [reward.id];
                            "
                    >
                      {{ $t('buttons.delete') }}
                    </CButton>
                  </CDropdownItem>
                </CDropdown>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>

      </table>
    </div>
    <RewardTemplatesModal
      :isShowRewardTemplatesModal="isShowRewardTemplatesModal"
      @selectReward="selectReward"
      @closeModal="closeModal"
    />
    <CreateRewardModal
      :isShowRewardModal="isShowRewardModal"
      :selectedReward="selectedReward"
      @updateRewardData="updateRewardData"
      @closeModal="closeModal"
    />
    <PreviewRewardModal
      :isShowPreviewModal="isShowPreviewModal"
      @closeModal="closeModal"
      :rewardData="selectedReward"
    />
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="messageGeneral"
      :title="delRewardsTitle"
      @doFunction="deleteReward"
      v-on:toggle-modal="closeModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RewardTemplatesModal from '@/views/ziqni/instant-wins/RewardTemplatesModal.vue';
import CreateRewardModal from '@/views/ziqni/instant-wins/CreateRewardModal.vue';
import { cloneDeep, uniqueId } from 'lodash';
import ClIcon from '@/shared/UI/ClIcon.vue';
import Modal from '@/shared/UI/Modal.vue';
import PreviewRewardModal from '@/components/mission/PreviewRewardModal.vue';


export default {
  name: 'RewardsCreation',
  components: { PreviewRewardModal, Modal, ClIcon, CreateRewardModal, RewardTemplatesModal },
  computed: {
    ...mapGetters('theme', ['theme']),
  },
  props: {
    entityType: String,
    rewardsData: Array,
    texts: Object
  },
  data() {
    return {
      isShowRewardTemplatesModal: false,
      isShowRewardModal: false,
      isShowPreviewModal: false,
      selectedReward: {},
      selectedRewards: [],
      activeToggleId: null,
      selectedId: null,
      selectedRewardIndex: null,
      deleteModal: false,
      messageGeneral: this.texts.createPage.rewards.deleteMessage,
      delRewardsTitle: this.texts.createPage.rewards.deleteTitle,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    selectedRewards: {
      deep: true,
      handler: function (val) {
        this.$emit('updateRewardData', val);
      },
    },
    isShowRewardTemplatesModal(newValue) {
      if (newValue) {
        this.checkModalState()
      }
    },
    isShowRewardModal(newValue) {
      if (newValue) {
        this.checkModalState()
      }
    },
    isShowPreviewModal(newValue) {
      if (newValue) {
        this.checkModalState()
      }
    },
    deleteModal(newValue) {
      if (newValue) {
        this.checkModalState()
      }
    }
  },
  methods: {
    async initialize() {
      if (this.rewardsData) {
        this.selectedRewards = cloneDeep(this.rewardsData);
      }

    },
    colorBtn(color) {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return color;
        }
        default: {
          return '';
        }
      }
    },
    addFromTemplate() {
      this.isShowRewardTemplatesModal = true;
    },
    createReward() {
      this.isShowRewardModal = true;
    },
    closeModal() {
      this.isShowRewardModal = false;
      this.isShowRewardTemplatesModal = false;
      this.isShowPreviewModal = false;
      this.deleteModal = false;
      this.selectedReward = {};
    },
    selectReward(reward) {
      this.isShowRewardTemplatesModal = false;
      this.selectedReward = reward;
      this.isShowRewardModal = true;
    },
    updateRewardData(reward) {
      if (this.selectedRewardIndex !== null) {
        this.$set(this.selectedRewards, this.selectedRewardIndex, reward[0]);
      } else {
        this.selectedRewards.push(reward[0]);
      }

      this.selectedRewardIndex = null;
      this.isShowRewardModal = false;
      this.selectedReward = {};
    },
    handleToggle(id) {
      this.activeToggleId = id;
    },
    showPreview(item) {
      this.selectedReward = {...item};
      this.isShowPreviewModal = true;
    },
    showEdit(item, index) {
      this.selectedReward = { ...item };
      this.selectedRewardIndex = index;
      this.isShowRewardModal = true;
    },
    deleteReward() {
      this.deleteModal = false;
      this.selectedRewards = this.selectedRewards.filter((item) => !this.selectedId.includes(item.id));
    },
    checkModalState() {
      const isRewardTemplatesModalOpen = this.isShowRewardTemplatesModal;
      const isCreateRewardModalOpen = this.isShowRewardModal;
      const isPreviewRewardModalOpen = this.isShowPreviewModal;
      const isDeleteModalModalOpen = this.deleteModal;

      if (isRewardTemplatesModalOpen || isCreateRewardModalOpen || isPreviewRewardModalOpen || isDeleteModalModalOpen) {
        const creatingBlock = document.querySelector('.creating-block');

        if (creatingBlock) {
          // this.observeModalBackdrop();
          this.observeModalContent();
        }
      }
    },
    observeModalBackdrop() {
      const observer = new MutationObserver(() => {
        const modalBackdrop = document.querySelector('.modal-backdrop.show') ?? document.querySelector('.modal-backdrop');

        if (modalBackdrop && !this.deleteModal) {

          // modalBackdrop.style.display = 'none';
          // modalBackdrop.style.opacity = '0';
          setTimeout(() => {
            observer.disconnect();
          }, 100)
        } else {
          // modalBackdrop.style.width = '503px'
          // modalBackdrop.style.height = '241px'
          // modalBackdrop.style.borderRadius = '12px';

          // modalBackdrop.style.position = 'absolute'; 0%, -50%)';
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    },
    observeModalContent() {
      const observer = new MutationObserver(() => {
        const modalContent = document.querySelector('.modal-content');
        if (modalContent) {
          modalContent.style.borderRadius = '15px';
          modalContent.style.border = '1px solid #3C4B64';
          setTimeout(() => {
            observer.disconnect();
          }, 100)
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  }
};
</script>


<style scoped lang="scss">
.reward-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
  background-color: white;
  border-radius: 12px;
}

.reward-table th,
.reward-table td {
  padding: 8px;
}

.reward-table th {
  font-size: 14px;
  font-weight: 400;
  color: #8A93A2;
  font-style: italic;
  background: #FFF;
}

.text-center.no-items {
  color: #282F37;
  font-size: 28px;
}

.reward-table th:first-child {
  border-top-left-radius: 10px;
}

.reward-table th:last-child {
  border-top-right-radius: 10px;
}

.reward-table td {
  font-size: 13px;
}

.reward-table tr:nth-child(even) {
  //background-color: #f9f9f9;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
