<template>
  <div class="contest">
    <!--  Competition Leaderboard -->
    <CompetitionLeaderboard v-if="isCompetitionLeaderboard" @toContestsList="toContestsList"/>
    <div v-else-if="!isPreviewVisible" class="content">
      <CRow>
        <CCol col="12">
          <TableHeader
            :fields="fields"
            :statuses="statuses"
            :isVisible="selectedContests.length"
            @checkRound="checkRound"
            @toggleFilter="showColumnFilter"
            @clearFilter="clearFilter"
            :originalFields="originalFields"
            @updateFields="updateFields"
            @createItem="createItem"
            @goToCompetitionLeaderboard="goToCompetitionLeaderboard"
            @reload="handleReload"
            :isSearchFieldVisible="false"
            :rounds="rounds"
            :isContestsTableView="isContestsTableView"
            :handleContestsView="handleContestsView"
            :isContestsTable="true"
          />
        </CCol>
      </CRow>
      <CRow class="mt-1">
        <CCol col="12">
          <CCard class="zq-card-table">
            <CCardBody v-if="isContestsTableView">
              <CDataTable
                id="contest-table"
                ref="contestTable"
                class="zq--table"
                :items="contests"
                :fields="fields"
                :column-filter="columnFilter"
                :items-per-page="itemsPerPage"
                sorter
                :responsive="true"
                :border="isDefaultTheme"
                :hover="isDefaultTheme"
                :striped="isDefaultTheme"
                :loading="loading"
                @pagination-change="itemsPerPageSelect"
                @page-change="paginationChange"
                :sorter-value.sync="sorterValue"
                @update:sorter-value="handleSortBy"
                @update:column-filter-value="handleColumnFilter"
              >
                <!--      ID          -->
                <template #id="{ item }">
                  <td>
                    <CLink @click="toPreview(item)">
                      {{ item.id }}
                    </CLink>
                  </td>
                </template>
                <!--      CREATED         -->
                <template #created="{item}">
                  <td>{{ dateFormate(item.created) }}</td>
                </template>
                <!--      "scheduledStartDate"         -->
                <template #scheduledStartDate="{item}">
                  <td>{{ dateFormate(item.scheduledStartDate) }}</td>
                </template>
                <!--      "scheduled End Date"         -->
                <template #scheduledEndDate="{item}">
                  <td>{{ dateFormate(item.scheduledEndDate) }}</td>
                </template>
                <!--     Description         -->
                <template #description="{item}">
                  <td v-if="item.description">
                    {{  truncateAndClearText(item.description) }}
                  </td>
                  <td v-else>-</td>
                </template>

                <!--     TermsAndConditions         -->
                <template #termsAndConditions="{item}">
                  <td v-if="item.termsAndConditions">
                    {{ truncateAndClearText(item.termsAndConditions) }}
                  </td>
                  <td v-else>-</td>
                </template>
                <!--      STATUS          -->
                <template #status="{item}">
                  <td>
                    <div :class="['status-box', `status-box__${item.status}`]">
                      {{ item.status }}
                    </div>
                  </td>
                </template>
                <!--      AUTO START        -->
                <template #autoStart="{item}">
                  <td>
                    <CSwitch
                      color="success"
                      shape="pill"
                      :checked="item.autoStart"
                      labelOn="Yes"
                      labelOff="No"
                      :disabled="true"
                    />
                  </td>
                </template>
                <!--     IS Public       -->
                <template #isPublic="{item}">
                  <td>
                    <CSwitch
                      color="success"
                      shape="pill"
                      :checked="item.isPublic"
                      labelOn="Yes"
                      labelOff="No"
                      :disabled="true"
                    />
                  </td>
                </template>
                <!--         COLUMN FILTER       -->
                <template v-for="item in columnFilterFields" #[item]>
                  <ColumnFilter
                    :key="item"
                    :field="item.slice(0, -7)"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <!--        HIDE FILTERS        -->
                <template v-for="itemHidden in hiddenFilterFields" #[itemHidden]>
                  <div :key="itemHidden"></div>
                </template>
                <!--       Select All        -->
                <template #select-header class="text-center">
                  <div class="position-relative zq--table-select-all" v-theme-select-all>
                    <ClCheckbox
                      :checkedProp="toggleFlag"
                      @handler="selectAll"
                      icon="fa-angle-down"
                    />
                  </div>
                </template>
                <!--       Select         -->
                <template #select="{ item }">
                  <td :data-id="item.id" class="text-center select-td fixed-column-start">
                    <ClCheckbox
                      :valueProp="item.id"
                      :checkedProp="selectedContests.indexOf(item.id) > -1"
                      @handler="checkContests"
                    />
                  </td>
                </template>
                <template #actions-header>
                  <div  v-theme-header-actions></div>
                </template>
                <template #actions-filter>
                  <div></div>
                </template>
                <!--        ACTIONS        -->
                <template #actions="{ item }">
                  <td
                    :data-id="item.id"
                    class="fixed-column-end"
                    :class="{'zq--active-fixed-column': activeToggleId === item.id}"
                  >
                    <div class="zq--table-actions-wrap">
                      <!--      Actions Dropdown                -->
                      <CDropdown
                        inNav
                        class="c-header-nav-items custom-dropbox"
                        placement="left-start"
                        add-menu-classes="p-0"
                      >
                        <template #toggler>
                          <CButton class="zq--table-actions--single" @click="handleToggle(item.id)">
                            <ClIcon cilIcon="cil-options" iconTypeProp="cil" imgIcon="actions-more"/>
                          </CButton>
                        </template>
                        <CDropdownItem class="p-0" v-for="dropdownItem in setDropDownItemActions(item.status)" :key="dropdownItem.title">
                          <CButton
                            pill
                            color="dark"
                            class="action-custom-button"
                            variant="ghost"
                            @click="dropDownChoice(dropdownItem.handler, item)"
                          >
                            {{ dropdownItem.title }}
                          </CButton>
                        </CDropdownItem>
                      </CDropdown>
                    </div>

                  </td>
                </template>
              </CDataTable>
              <TableFooter
                :page="page"
                @updatePagenation="paginationChange"
                :pages="pages"
                :total="resultCount"
                :itemsPerPage="itemsPerPage"
                @updateItemPerPage="itemsPerPageSelect"
              />
            </CCardBody>
            <CCard class="zq--wizard-card" v-if="!isContestsTableView">
              <CCardBody>
                <h4 class="mb-4">{{ texts.leaderboardDetail.titleTournament }}</h4>
                <ConnectionsTable :key="connectionsTableKey" @reload="handleReload"/>
<!--                <ContestTournamentTable />-->
              </CCardBody>
            </CCard>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <!--  PREVIEW CONTEST -->
    <PreviewContest
      v-else
      :contest="currentContest"
      :contestId="contestId"
      @goBack="goBack"
    />
    <!--       MODAL       -->
    <Modal
      :modalShow="modalActive"
      :title="modalTitle"
      :messageGeneral="modalMessage"
      @doFunction="modalConfirm"
      @toggle-modal="changeModal"
    />
    <Modal
      :modalShow="cloneModalActive"
      :title="cloneModalTitle"
      :messageGeneral="cloneModalMessage"
      @doFunction="cloneModalConfirm"
      @toggle-modal="cloneChangeModal"
    />
    <!-- -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ColumnFilter from '@/components/table/ColumnFilter';
import TableHeader from '@/components/table/Header';
import TableFooter from '@/components/table/Footer';
import ClIcon from '@/shared/UI/ClIcon';
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';
import PreviewContest from './PreviewContest';
import Modal from '@/shared/UI/Modal.vue';
// import ContestTournamentTable from '../LeaderboardDetail/components/ContestTournamentTable';
import CompetitionLeaderboard from './CompetitionLeaderboard';
import ConnectionsTable from './components/ConnectionsTable';
import { competitionsTexts } from '@/config/pageTexts/competitions.json';

import { pageConfig } from '@/config';
import { cloneDeep, range } from 'lodash';
import { dateFormate } from '@/utils';

export default {
  components: {
    TableHeader,
    ColumnFilter,
    TableFooter,
    PreviewContest,
    ClIcon,
    ClCheckbox,
    Modal,
    // ContestTournamentTable,
    ConnectionsTable,
    CompetitionLeaderboard
  },
  data() {
    return {
      isPreviewVisible: false,
      isCompetitionLeaderboard: false,
      contestId: '',
      currentContest: {},
      connectionsTableKey: 0,
      columnFilterFields: [
        "id-filter",
        "spaceName-filter",
        "created-filter",
        "competitionId-filter",
        "number-filter",
        "name-filter",
        "round-filter",
        "roundType-filter",
        "entrantsFromContest-filter",
        "minNumberOfEntrants-filter",
        "isPublic-filter",
        "scheduledStartDate-filter",
        "scheduledEndDate-filter",
        "status-filter",
        "statusCode-filter",
        "description-filter",
        "termsConditions-filter",
        "autoStart-filter",
        "metadata-filter",
      ],
      hiddenFilterFields: [
        'select-filter',
        'icon-filter',
      ],
      itemsPerPage: pageConfig.itemsPerPage,
      query: [
        {
          "queryField": "competitionId",
          "queryValues": [
            this.$route.params.id
          ]
        }
      ],
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      toggleFlag: false,
      statuses: [],
      status: '',
      selectedContests: [],
      activeToggleId: null,
      modalActive: false,
      cloneModalActive: false,
      modalTitle: '',
      modalMessage: '',
      cloneModalTitle: '',
      cloneModalMessage: '',
      isContestsTableView: true,
      texts: {
        ...competitionsTexts
      },
      contestDropDownActions: [
        {
          title: this.$t('buttons.preview'),
          handler: 'toPreview',
        },
        {
          title: this.$t('buttons.edit'),
          handler: 'editContest',
        },
        {
          title: this.$t('buttons.clone'),
          handler: 'cloneContest',
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'deleteContest',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('contests', ['contest', 'contests', 'fields', 'pages', 'loading', 'resultCount', 'originalFields']),
    ...mapGetters('competitions', ['competition']),
    ...mapGetters('theme', ['theme']),
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        }
      }
    },
    isDefaultTheme() {
      return this.theme === 'default'
    },
    rounds() {
      return range(1, 21).reduce((acc, item) => {
        const text = item === 1 ? 'Round' : 'Rounds';
        return [
          ...acc,
          {
            value: item,
            label: `${item} ${text}`
          }]
      }, [{value: 'All Rounds', label: 'All Rounds'}]);
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
    if (this.$route.query && this.$route.query.contestId) {
      const item = this.contests.filter(c => c.id === this.$route.query.contestId);
      if (item && item.length) {
        this.toPreview(item[0]);
      }
    }
  },
  methods: {
    ...mapActions('contests', [
      'handleGetContests',
      'handleGetContestsToClone',
      'handleCreateContests',
      'handleGetContestsByQuery',
      'handleFields',
      'handleClearFilter',
      'handleDeleteContest',
      'handleGetContests_item',
      'handleUpdateContestsState'
    ]),
    async initialize() {
      let competitionName = this.competition.name;
      let contest = this.contest;

      if (!competitionName) {
        const competitions = await this.handleGetCompetitions({idArray: [this.$router.currentRoute.params.id]});
        competitionName = competitions[0].name
      }

      if (typeof contest !== 'object' || !Object.keys(contest).length) {
        const contests = await this.handleGetContests_item([this.$router.currentRoute.params.contestId], 1, 0);
        contest = contests[0];
      }

      this.handleGetContestsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: (this.skip - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
      this.handleFields();
    },
    async handleReload() {
      this.connectionsTableKey++;
      await this.initialize();
    },
    // FIX Z-INDEX ISSUE
    handleToggle(id) {
      this.activeToggleId = id;
    },
    // goToLeaderboard() {
    //   this.$router.push({
    //     name: 'ContestLeaderboard',
    //     params: {
    //       id: this.$router.currentRoute.params.id,
    //       contestId: this.contestId
    //     },
    //   });
    // },
    toContestsList() {
      this.isCompetitionLeaderboard = false;
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    updateFields(val) {
      this.handleFields(val);
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetContests({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    paginationChange(val) {
      this.page = val;
      this.handleGetContestsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    handleSortBy(e) {
      this.sortBy = [
        {
          queryField: e.column,
          order: e.asc ? 'Asc' : 'Desc',
        },
      ];
      this.page = 1;
      this.handleGetContestsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    handleColumnFilter(e) {
      if (e.field && e.value) {
        this.query[e.field] = e.value.trim();
      } else {
        delete this.query[e.field];
      }
      this.handleGetContestsByQuery({
        queryRequest: {
          must: this.query,
          sortBy: this.sortBy,
          skip: (this.skip - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    checkContests({event}) {
      const index = this.selectedContests.indexOf(event.target.value);
      if (index_old > -1) {
        this.selectedContests.splice(index_old, 1);
      } else {
        this.selectedContests.push(event.target.value);
      }
    },
    selectAll() {
      const contests = cloneDeep(this.contests);
      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag)
        contests.forEach(item => this.selectedContests.push(item.id));
      else this.selectedContests = [];
    },
    formatFilter() {
      this.$refs.contestTable.clean();
      this.page = 1;
      this.query = {};
      this.sortBy = [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ];
      this.handleGetContests({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    clearFilter() {
      this.handleClearFilter();
    },
    showColumnFilter(val) {
      this.columnFilter = val;
      if (!val) {
        this.formatFilter();
      }
    },
    checkRound(val) {
      let roundQuery = [];
      if (val !== 'All Rounds') {
        roundQuery = [
          {
            queryField: 'round',
            queryValues: [val]
          }
        ]
      }

      this.handleGetContestsByQuery({
        queryRequest: {
          must: [...this.query, ...roundQuery],
          sortBy: this.sortBy,
          skip: (this.skip - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    createItem() {
      this.$router.push({
        name: "CreateContest"
      })
    },
    goToCompetitionLeaderboard() {
      this.isCompetitionLeaderboard = true;
    },
    shortedText(text) {
      if (text && text.length > 20) {
        return `${text.split(0, 20)[0].replace(/(<([^>]+)>)/gi, "").substring(0, 20)}...`
      }
      return text
    },
    truncateAndClearText(text) {
      text = text.replace(/<[^>]*>?/gm, ' ').replace(/\s+/g, ' ').trim();
      let sliced = text.slice(0, 100);
      if (sliced.length < text.length) {
        sliced += '...';
      }

      return sliced;
    },
    setDropDownItemActions(status) {
      const isFirstSpace = localStorage.getItem('zq-space-name') === 'first-space'
      let actions = [...this.contestDropDownActions];

      switch (status) {
        case 'Ready':
          actions = this.setReadyContestActions(actions);
          break;
        case 'Active':
          actions = this.setActiveContestActions(actions);
          break;
        case 'Finished':
          actions = this.setFinishedContestActions(actions);
          break;
        case 'Finalised':
          actions = this.setFinalisedContestActions(actions);
          break;
        default:
          break;
      }

      // if (isFirstSpace) {
      //   const editIndex = actions.findIndex(action => action.title === this.$t('buttons.edit'));
      //   if (editIndex !== -1) {
      //     actions.splice(editIndex + 1, 0, {
      //       title: this.$t('buttons.clone'),
      //       handler: 'cloneContest',
      //     });
      //   }
      // }

      return actions;
    },
    setActiveContestActions() {
      return [
        {
          title: 'Finish',
          handler: 'finishedContest',
        },
        {
          title: 'Cancel',
          handler: 'cancellingContest',
        },
        ...this.contestDropDownActions
      ]
    },
    setReadyContestActions() {
      return [
        {
          title: 'Start',
          handler: 'startingContest',
        },
        {
          title: 'Cancel',
          handler: 'cancellingContest',
        },
        ...this.contestDropDownActions
      ]
    },
    setFinishedContestActions() {
      return [
        {
          title: 'Finalise',
          handler: 'finalisedContest',
        },
        {
          title: 'Cancel',
          handler: 'cancellingContest',
        },
        ...this.contestDropDownActions.filter(action => action.title !== 'Delete')
      ]
    },
    setFinalisedContestActions() {
      return [
        {
          title: 'Cancel',
          handler: 'cancellingContest',
        },
        ...this.contestDropDownActions.filter(action => action.title !== 'Delete')
      ]
    },
    dropDownChoice(action, item) {
      this[action](item);
    },
    editContest(item) {
      this.$router.push({
        name: 'EditContest',
        params: {
          id: item.id,
          compId: this.$router.currentRoute.params.id
        },
      });
    },
    async cloneContest(item) {
      await this.$router.push({
        name: 'CloneContest',
        params: { cloneId: item.id },
      });
    },
    async deleteContest(item) {
      const updateContestStateRequestArray = [{ contestId: item.id, status: 'Deleted' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    async startingContest(item) {
      const updateContestStateRequestArray = [{ contestId: item.id, status: 'Active' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    async cancellingContest(item) {
      const updateContestStateRequestArray = [{ contestId: item.id, status: 'Cancelled' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    async finishedContest(item) {
      const updateContestStateRequestArray = [{ contestId: item.id, status: 'Finished' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    async finalisedContest(item) {
      const updateContestStateRequestArray = [{ contestId: item.id, status: 'Finalised' }];
      await this.handleUpdateContestsState({updateContestStateRequestArray});
    },
    toPreview(item) {
      this.currentContest = item;
      this.contestId = item.id;
      this.$router.push({
        name: 'PreviewContest',
        params: {
          id: this.$router.currentRoute.params.id,
          contestId: item.id
        },
      })
      this.isPreviewVisible = true;
    },
    goBack() {
      this.isPreviewVisible = false;
    },
    changeModal(value) {
      this.modalActive = value
    },
    modalConfirm() {
      this.modalActive = false;
      this.selectedContests = [];
    },
    cloneModalConfirm () {

    },
    cloneChangeModal () {

    },
    handleContestsView(value) {
      this.isContestsTableView = value === 'tableView';
    },
  },
}
</script>

<style lang="scss">
$icons-font-size: 1.2rem;

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}

.contest {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);;
    border-top: 1px solid var(--zq-content-border-color);


    .members-action .c-header-nav-items {
      list-style: none;
    }

    .action-custom-button {
      width: 100%;
    }

    .competitions-icon-actions {
      position: relative;
      display: flex;
    }

  }

}
</style>
