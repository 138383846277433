export const convertDate = (input) => {
  if (input.startsWith('P')) {
    return parseDuration(input);
  } else {
    return parseDate(input);
  }
}

function parseDuration(duration) {
  const regex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?/;
  const match = regex.exec(duration);

  if (!match) return 'Invalid duration';

  const [_, years, months, weeks, days, hours, minutes, seconds] = match.map(v => parseInt(v || '0', 10));

  const parts = [];
  if (years) parts.push(`${years} year${years > 1 ? 's' : ''}`);
  if (months) parts.push(`${months} month${months > 1 ? 's' : ''}`);
  if (weeks) parts.push(`${weeks} week${weeks > 1 ? 's' : ''}`);
  if (days) parts.push(`${days} day${days > 1 ? 's' : ''}`);
  if (hours) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  if (minutes) parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  if (seconds) parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);

  return parts.length > 0 ? parts.join(', ') : '0 seconds';
}

function parseDate(date) {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate)) return 'Invalid date';

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC'
  };

  return parsedDate.toLocaleString('en-GB', options).replace(',', '');
}
