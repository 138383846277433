<template>
  <div class="mission-block">
    <div class="image-wrapper">
      <img :src="mission.image" alt="i"/>
    </div>
    <div class="mission-info">
      <span class="mission-name">{{ mission.name }}</span>
      <span class="reward-data" v-if="mission.rewards.length > 0">
        <span>
          {{ mission.rewards[0].name }}
        </span>
        <span>{{ mission.rewards[0].rewardValue }}</span>

      </span>
    </div>


    <button @click="toggleConnectionOptions">+</button>
    <ConnectionOptions
      v-if="showOptions"
      @chooseConnection="addConnection"
    />
  </div>
</template>

<script>
import ConnectionOptions from '@/components/mission/ConnectionOptions.vue';

export default {
  name: 'MissionBlock',
  components: { ConnectionOptions },
  props: {
    mission: Object,
  },
  data() {
    return {
      showOptions: false,
    };
  },
  methods: {
    toggleConnectionOptions() {
      this.showOptions = !this.showOptions;
    },
    addConnection(type) {
      console.log('type', type);
      this.$emit('addConnection', { missionId: this.mission.id, type });
    },
  },
};
</script>

<style scoped lang="scss">
.mission-block {
  width: 200px;
  height: 80px;
  border: 1px solid #406A8C;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #DCE6EF;
  border-radius: 10px;
  position: fixed;

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 51px;
    border-radius: 5px;
    border: 1px solid #406A8C;
    overflow: hidden;
    flex-shrink: 0;

    & > img {
      height: 100%;
      object-fit: contain;
      max-width: 100%;
      border-radius: 5px;
    }
  }

  .mission-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-left: 10px;
    flex-grow: 1;

    .mission-name {
      font-size: 16px;
      color: #141E28;
      text-transform: capitalize;
    }

    .reward-data {
      font-size: 14px;
      color: #304F69;
    }
  }


  & > button {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #BB1196;
    color: #fff;
    border-radius: 5px;
    border: none;
  }

  .connection-options {
    position: absolute;
    top: 50%;
    right: -50%;
    transform: translateY(-50%);
  }
}
</style>
