<template>
  <div class="create-scheduling-step">
    <CForm
      ref="createScheduling"
      novalidate
      v-on:submit.prevent
    >
      <div class="form-content content">
        <!--   Scheduling section     -->
        <div v-if="creationType !== 'contest'">
          <h3 class="mb-3 bread-content"><span>{{ creationType === 'simple' ? '2.1' : '2' }}. {{ texts.scheduling.title }}</span></h3>
          <!--  Auto Start     -->
          <SwitchRow
            :label="texts.scheduling.autoStartLabel"
            :toolTipText="descriptions.autoStart"
            :valueProp="autoStart"
            :disabled="isFieldDisabled"
            @swipeHandler="swipeAutoStartHandler"
            @updateHandler="autoStartUpdate"
          />
          <!--  Auto STOP     -->
          <SwitchRow
            :label="texts.scheduling.autoStopLabel"
            :valueProp="autoStop"
            @swipeHandler="swipeAutoStopHandler"
            @updateHandler="autoStopUpdate"
            :disabled="isFieldDisabled"
            :toolTipText="descriptions.autoStop"
          />
          <!--   Available from     -->
          <CRow>
            <CCol col="12" md="3" xl="2" class="text-left">
              <label class="col-form-label font-italic">{{ texts.scheduling.startLabel }}</label>
            </CCol>
            <CCol col="10" sm="10" class="schedule-date">
              <DatePicker
                :dateProp="scheduledStartDate"
                @input="setStartDate"
                :laceholder="texts.scheduling.startPlaceholder"
                :isValid="starDataValidate !== false"
                class="mr-3 mb-3"
                :disabled="isFieldDisabled"
                :disabledDate="disabledStartDate"
              />
              <label class="col-form-label font-italic mr-3">{{ texts.scheduling.endLabel }}</label>
              <div class='schedule-endDate'>
                <DatePicker
                  :fieldName="'scheduledEndDate'"
                  :dateProp="scheduledEndDate"
                  @input="setEndDate"
                  :isValid="endDateValidate !== false"
                  :placeholder="texts.scheduling.endPlaceholder"
                  :disabled="isFieldDisabled"
                  :disabledDate="disabledEndDate"
                  :disabled-time="disabledEndDateTime"
                />
                <IconWithTooltip :text="descriptions.availableFrom"/>
                <div v-if="invalidEndDate" class="invalid-end-date">{{ endDateErrorMessage }}</div>
              </div>
            </CCol>
          </CRow>
          <!--    Container duration    -->
          <ClFormRow
            :label="texts.scheduling.durationLabel"
            col-content="10"
            md-content="4"
            sm-content="5"
            lg-content="4"
            xl-content="3"
            tooltip-position=""
          >
            <template #content>
              <span class="col-form-label">{{ durationTime }}</span>
            </template>
          </ClFormRow>
          <!--   Number Of Rounds   -->
<!--          <div v-if="creationType === 'advanced'">
            <ClFormRow :label="texts.scheduling.numberLabel">
              <template #content>
                <ClSelect
                  class="text-capitalize"
                  :options="numberOfRoundsOption"
                  :placeholder="texts.scheduling.numberPlaceholder"
                  :valueProp="formData.numberOfRounds"
                  size="xxl"
                  :invalidFeedback="texts.invalidFeedback"
                  :selectLabel="selectLabel"
                  :disabled="isFieldDisabled"
                  track-by="value"
                  label-track="label"
                  @checkValue="numberOfRoundsUpdate"
                />
              </template>
            </ClFormRow>
          </div>-->
        </div>
        <!--   Scoring section   -->
        <div v-if="creationType !== 'advanced'">
          <div class="mb-4 mr-2 bread-content">
            <span>{{ creationType === 'contest' ? "2." : '2.2.' }} {{ texts.scheduling.scoringTitle }}</span>
          </div>
          <!--  Round   -->
          <ClFormRow
            v-if="creationType === 'contest' && isRounds"
            :label="texts.scheduling.roundLabel"
          >
            <template #content>
              <ClSelect
                class="text-capitalize"
                :options="numberOfRoundsOption"
                :placeholder="texts.scheduling.roundPlaceholder"
                :valueProp="formData.round"
                size="xxl"
                :invalidFeedback="texts.invalidFeedback"
                :selectLabel="selectLabel"
                :disabled="isFieldDisabled"
                track-by="value"
                label-track="label"
                @checkValue="roundUpdate"
              />
            </template>
          </ClFormRow>
          <CRow v-if="creationType === 'contest'">
            <CCol col="12" md="3" xl="2" class="text-left">
              <label class="col-form-label font-italic">{{ texts.scheduling.startLabel }}</label>
            </CCol>
            <CCol sm="4" class="schedule-date">
              <DatePicker
                :fieldName="'scheduledStartDate'"
                :dateProp="scheduledStartDate"
                @input="setStartDate"
                :isValid="starDataValidate !== false"
                :placeholder="texts.scheduling.startPlaceholder"
                class="mr-3 mb-3"
                :disabled="isFieldDisabled"
                :disabledDate="disabledStartDate"
                required
                :class="{'zq--select--error': starDataValidate === false}"
              />
              <IconWithTooltip :text="descriptions.availableFrom"/>
            </CCol>
          </CRow>
          <CRow v-if="creationType === 'contest'">
            <CCol col="12" md="3" xl="2" class="text-left">
              <label class="col-form-label font-italic mr-3">{{ texts.scheduling.endLabel }}</label>
            </CCol>
            <CCol sm="4" class="schedule-date">
              <DatePicker
                :fieldName="'scheduledEndDate'"
                :dateProp="scheduledEndDate"
                @input="setEndDate"
                :isValid="endDateValidate !== false"
                :placeholder="texts.scheduling.endPlaceholder"
                :disabled="isFieldDisabled"
                :disabledDate="disabledEndDate"
                :disabled-time="disabledEndDateTime"
                required
                :class="{'zq--select--error': starDataValidate === false}"
              />
              <span v-if="!endDateValidate" class="icon-validation-error">
                {{ invalidContestEndDate }}
              </span>
              <div v-if="invalidEndDate" class="invalid-end-date">{{ endDateErrorMessage }}</div>
            </CCol>
          </CRow>
          <!--    Entrants    -->
          <ClFormRow
            v-if="creationType === 'contest' && isEntrants && formData.round > 1"
            :label="texts.scheduling.entrantsLabel"
          >
            <template #content>
              <multiselect
                v-model="formData.entrantsFromContest"
                :options="optionsEntrantsFromContest"
                track-by="id"
                label="label"
                :multiple="true"
                :preserve-search="true"
                :hide-selected="true"
                :placeholder="texts.scheduling.entrantsPlaceholder"
                :selectLabel="selectLabel"
                :disabled="isFieldDisabled"
                class="custom-multi-select zq--multiselect"
              >
                <template #caret>
                  <div></div>
                </template>
              </multiselect>
              <img
                src="../../../../assets/icons/search.svg"
                width="16"
                height="16"
                alt="search"
                class="zq--multiselect-search-icon"
              >
            </template>
          </ClFormRow>
          <!-- Scoring Strategy  -->
          <ClFormRow
            class="mb-4"
            :label="texts.scheduling.scoringLabel"
            :isTooltip="false"
          >
            <template #content>
              <ClSelect
                name="strategyType"
                class="text-capitalize"
                :options="scoringStrategyOption"
                :placeholder="texts.scheduling.scoringPlaceholder"
                :valueProp="formData.strategies.strategyType"
                size="xxl"
                required
                :invalidFeedback="texts.invalidFeedback"
                :disabled="isFieldDisabled"
                track-by="value"
                :selectLabel="selectLabel"
                :is-valid="scoringStrategyValidate"
                :is-description="true"
                label-track="label"
                @checkValue="scoringStrategyUpdate"
              />
              <IconWithTooltip :text="descriptions.scoringStrategy"/>
            </template>
          </ClFormRow>
          <!--  Scoring Strategy VALUE      -->
          <ClFormRow
            v-if="isScoreValueVisible"
            :label="texts.scheduling.valueLabel"
          >
            <template #content>
              <CInput
                type="number"
                :placeholder="texts.scheduling.valuePlaceholder"
                v-model.number="scoringStrategyValue"
                @update:value="handleUpdateScoringValue($event, formData.strategies.strategyType)"
                add-input-classes="col-sm-12"
                :disabled="isFieldDisabled"
                min="1"
                step="1"
                :invalidFeedback="texts.invalidFeedback"
              />
            </template>
          </ClFormRow>
          <!--  Default Ranking Strategy    -->
          <SwitchRow
            :label="texts.scheduling.defaultLabel"
            :valueProp="defaultRanking"
            @swipeHandler="swipeDefaultRankingHandler"
            @updateHandler="defaultRankingUpdate"
            :disabled="isFieldDisabled"
            :toolTipText="descriptions.defaultRankingStrategy"
          />
          <!--    Other Switches    -->
          <div v-if="!defaultRanking">
            <!--  Score ranking order descending    -->
            <SwitchRow
              :label="texts.scheduling.scoreLabel"
              :valueProp="scoreDesc"
              @swipeHandler="swipeScoresRankingOrderHandler"
              @updateHandler="scoresRankingOrderUpdate"
              :disabled="isFieldDisabled"
            />
            <!--  Time ranking order descending    -->
            <SwitchRow
              :label="texts.scheduling.timeLabel"
              :valueProp="timeDesc"
              @swipeHandler="swipeTimeRankingOrderHandler"
              @updateHandler="timeRankingOrderUpdate"
              :disabled="isFieldDisabled"
            />
            <!--  Rank order by scores first    -->
            <SwitchRow
              :label="texts.scheduling.rankLabel"
              :valueProp="scoreFirst"
              @swipeHandler="swipeScoreFirstHandler"
              @updateHandler="scoreFirstUpdate"
              :disabled="isFieldDisabled"
            />
            <!--  Discard time when ranking   -->
            <SwitchRow
              :label="texts.scheduling.discardTimeLabel"
              :valueProp="ignoreTime"
              @swipeHandler="swipeIgnoreTimeHandler"
              @updateHandler="ignoreTimeUpdate"
              :disabled="isFieldDisabled"
            />
            <!--  Discard score when ranking   -->
            <SwitchRow
              :label="texts.scheduling.discardScoreLabel"
              :valueProp="ignoreScore"
              @swipeHandler="swipeIgnoreScoreHandler"
              @updateHandler="ignoreScoreUpdate"
              :disabled="isFieldDisabled"
            />
            <!--  Exclude If Goal Not Reached   -->
            <SwitchRow
              :label="texts.scheduling.excludeIfGoalNotReached"
              :valueProp="excludeIfGoalNotReached"
              @swipeHandler="swipeExcludeIfGoalNotReached"
              @updateHandler="ignoreExcludeIfGoalNotReached"
              :disabled="isFieldDisabled"
            />
          </div>
        </div>
      </div>
    </CForm>
  </div>
</template>

<script>
import { range } from "lodash";
import DatePicker from '@/shared/components/DatePicker';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { getDuration } from '@/utils/timeUtils';
import SwitchRow from '@/shared/components/SwitchRow';
import { isCompFieldDisabled } from "@/utils/validationUtils";
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import ClSelect from "@/shared/components/formComponents/ClSelect";
import { formConfig, pageConfig } from "@/config";
import { competitionsTexts } from "@/config/pageTexts/competitions.json";
import { mapActions } from "vuex";
import { competitions } from '@/config/descriptions/competitions.json'
import { dateFormate } from '@/utils/dateFormate';

export default {
  name: "CreateCompetitionScheduling",
  components: {
    DatePicker,
    SwitchRow,
    IconWithTooltip,
    ClFormRow,
    ClSelect
  },
  props: {
    schedulingData: Object,
    constraintsData: Array,
    isStartDate: Boolean,
    isEndDate: Boolean,
    isStrategy: Boolean,
    creationType: {
      type: String,
      default: 'simple'
    },
    status: {
      type: String,
      default: 'Ready'
    },
    isRounds: {
      type: Boolean,
      default: true
    },
    isEntrants: {
      type: Boolean,
      default: true
    },
    descriptions: Object,
    competitionStartDate: Date,
    competitionEndDate: Date,
    competitionId: String
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      scoringStrategiesDescriptions: {
        ...competitions.scoringStrategies
      },
      endDateErrorMessage: competitions.create.single.scheduling.endDateErrorMessage,
      description: '',
      activeHover: false,
      selectLabel: formConfig.selectLabel,
      optionsEntrantsFromContest: [],
      numberOfRoundsOption: [],
      scheduledStartDate: '',
      scheduledEndDate: '',
      autoStart: true,
      autoStop: true,
      defaultRanking: true,
      scoreDesc: false,
      timeDesc: false,
      scoreFirst: false,
      ignoreTime: false,
      ignoreScore: false,
      excludeIfGoalNotReached: false,
      // Scoring
      scoringItems: ['timeDesc', 'scoreDesc', 'scoreFirst', 'ignoreTime', 'ignoreScore'],
      scoringStrategyOption: [
        {
          value: 'TotalCumulative',
          label: 'Cumulative Strategy',
          description: 'The most basic strategy where all points are added up and totals are displayed on the leaderboard.'
        },
        {
          value: 'SumBest',
          label: 'Highest Only',
          description: 'This requires a value to indicate how many of the highest points/ scores will be calculated and added to the leaderboard.'
        },
        {
          value: 'LimitedTo',
          label: 'Sum over Count',
          description: 'This requires a value to indicate how many events will be summed up and displayed on the leaderboard.'
        },
        {
          value: 'FirstTo',
          label: 'Sum over Count - including a goal in time marker',
          description: 'This requires a value to indicate how many points need to be achieved to meet the goal, for example, the first person to reach 100. The value will be capped as the selected scoring strategy.'
        }
      ],
      scoringStrategyToolTipValue: null,
      constraints: [],
      scoringStrategyValue: null,
      sortBy: pageConfig.sortBy,
      formData: {
        entrantsFromContest: [],
        round: 1,
        numberOfRounds: 1,
        scheduledStartDate: '',
        scheduledEndDate: '',
        strategies: {
          strategyType: "",
          rankingStrategy: {
            constraints: []
          },
          scoringStrategy: {
            limitUpdatesTo: 0,
            sumBestXOf: 0,
            lastUpdateTimeStamp: 0,
            recordTimeWhenSumReaches: 0
          }
        },
      },
      starDataValidate: null,
      endDateValidate: null,
      scoringStrategyValidate: null,
      invalidContestEndDate: null,
      invalidEndDate: false
    }
  },
  computed: {
    isFieldDisabled() {
      return isCompFieldDisabled(this.status);
    },
    durationTime() {
      return getDuration(this.formData.scheduledStartDate, this.formData.scheduledEndDate);
    },
    isScoreValueVisible() {
      return this.formData.strategies.strategyType !== 'TotalCumulative' && this.formData.strategies.strategyType !== '';
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('contests', ['handleGetContestsByQuery']),
    async initialize() {
      // The strategy is only present in simple competitions and contest
      if (this.creationType === 'simple') {
        const scoringValue = (Object.values(this.schedulingData.strategies.scoringStrategy).filter(item => item));
        this.scoringStrategyValue = scoringValue[0] ? Number(scoringValue[0]) : null;
        // set initial switches value
        this.setInitialSwitchValue(['timeDesc', 'scoreDesc', 'scoreFirst', 'ignoreTime', 'ignoreScore', 'autoStart', 'autoStop', 'defaultRanking']);
      } else if (this.creationType === 'contest') {
        const scoringValue = (Object.values(this.schedulingData.strategies.scoringStrategy).filter(item => item));
        this.scoringStrategyValue = scoringValue[0] ? Number(scoringValue[0]) : null;
        // set initial switches value
        this.setInitialSwitchValue(['excludeIfGoalNotReached', 'timeDesc', 'scoreDesc', 'scoreFirst', 'ignoreTime', 'ignoreScore', 'defaultRanking']);

        this.numberOfRoundsOption = this.getRoundsOption();

        const endDate =  new Date(this.competitionEndDate);
        const startDate =  new Date(this.competitionStartDate);

        this.scheduledStartDate = new Date( new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000));
        this.scheduledEndDate =  new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);
        this.formData.scheduledStartDate = new Date( new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000));
        this.formData.scheduledEndDate =  new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);

      } else {
        // set initial switches value
        this.setInitialSwitchValue(['autoStart', 'autoStop']);

        this.numberOfRoundsOption = this.getRoundsOption();
      }

      this.constraints = this.constraintsData;
      this.formData = this.schedulingData;

      // if (this.schedulingData.scheduledEndDate) this.scheduledEndDate = new Date(this.schedulingData.scheduledEndDate);
      // if (this.schedulingData.scheduledStartDate) this.scheduledStartDate = new Date(this.schedulingData.scheduledStartDate);

      if (this.schedulingData.scheduledEndDate) {
        const endDate =  new Date(this.schedulingData.scheduledEndDate);
        this.scheduledEndDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);
      }
      if (this.schedulingData.scheduledStartDate) {
        const startDate = new Date(this.schedulingData.scheduledStartDate);
        this.scheduledStartDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
      }

      // set initial autoStart and autoStop for constraints
      this.updateConstraints(this.autoStart, 'autoStart');
      this.updateConstraints(this.autoStop, 'autoStop');
    },
    async getOptionsEntrantsFromContest() {
      const contests = await this.handleGetContestsByQuery({
        queryRequest: {
          must: [
            {
              "queryField": "competitionId",
              "queryValues": [
                this.competitionId ? this.competitionId : this.$route.params.id
              ]
            },
            {
              "queryField": "round",
              "queryValues": [
                this.formData.round - 1
              ]
            }
          ],
          sortBy: this.sortBy,
          skip: 0,
          limit: 99,
        }
      });

      if (contests && contests.length) {
        this.optionsEntrantsFromContest = contests.map(c => ({
          id: c.id,
          label: `${c.name} (${c.id})`
        }));

        if (this.formData.entrantsFromContest && this.formData.entrantsFromContest.length) {
          this.formData.entrantsFromContest = this.formData.entrantsFromContest.map(selectedId => {
            const matchingOption = this.optionsEntrantsFromContest.find(option => option.id === selectedId);
            return matchingOption || selectedId;
          });
        }
      }
    },
    setInitialSwitchValue(switches = []) {
      switches.map(item => {
        this[item] = this.schedulingData[item];
      })
    },
    getRoundsOption() {
      return range(1, 21).reduce((acc, item) => {
        const text = item === 1 ? 'Round' : 'Rounds';
        return [
          ...acc,
          {
            value: item,
            label: `${item} ${text}`
          }]
      }, [])
    },
    async roundUpdate({value}) {
      this.formData.round = value;

      if (value && value > 1) {
        await this.getOptionsEntrantsFromContest();
      }

      this.$emit('updateSchedulingData', {
        ...this.schedulingData,
        formData: this.formData,
      })
    },
    numberOfRoundsUpdate({value}) {
      this.formData.numberOfRounds = value;
      this.$emit('updateSchedulingData', {
        ...this.schedulingData,
        formData: this.formData,
      })
    },
    setStartDate(date) {
      if (date) this.starDataValidate = true;

      const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      this.formData.scheduledStartDate = date ? isoDate : "";
      this.scheduledStartDate = date;
    },
    setEndDate(date) {
      this.invalidEndDate = false;

      if (date) {
        const dateEndUTC = new Date(date.getTime() - date.getTimezoneOffset() * 60000).getTime();
        const competitionEndUTC = new Date(this.competitionEndDate).getTime();

        if (this.competitionEndDate && dateEndUTC > competitionEndUTC) {
          this.endDateValidate = false;
          this.invalidContestEndDate =
            'The end date of the contest can\'t be later than the end of the competition' +
            ' (' + dateFormate(this.competitionEndDate) + ')';
          return;
        }
      }

      this.invalidContestEndDate = null;

      const isoDate = date
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
        : "";

      this.formData.scheduledEndDate = isoDate;
      this.scheduledEndDate = date;
    },
    disabledStartDate(d) {
      return d < new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledEndDate(d) {
      const date = this.scheduledStartDate ? new Date(this.scheduledStartDate) : new Date();
      date.setHours(0, 0, 0, 0);
      return d < date;
    },
    disabledEndDateTime(d) {
      const hours = this.scheduledStartDate.getHours();
      const minutes = this.scheduledStartDate.getMinutes();
      const seconds = this.scheduledStartDate.getSeconds();

      return d < new Date(new Date(this.scheduledStartDate).setHours(hours, minutes, seconds));
    },
    updateConstraints(val, constraint) {
      if (val) {
        if (!this.constraints.includes(constraint)) {
          this.constraints.push(constraint);
        }
      } else {
        this.constraints = this.constraints.filter(item => item !== constraint);
      }
      this.$emit('updateConstraints', this.constraints);
    },
    updateStrategyConstraints(val, constraint) {
      if (val) {
        if (!this.formData.strategies.rankingStrategy.constraints.includes(constraint)) {
          this.formData.strategies.rankingStrategy.constraints.push(constraint);
        }
      } else {
        this.formData.strategies.rankingStrategy.constraints = this.formData.strategies.rankingStrategy.constraints.filter(item => item !== constraint);
      }
    },
    // Emit switch value to the root data
    toggleSwitch(field, value) {
      this.$emit('updateSchedulingSwitch', field, value)
    },
    setScoringStrategyToolTipValue(value) {
      this.scoringStrategyToolTipValue = this.scoringStrategiesDescriptions[value];
    },
    scoringStrategyUpdate({value}) {
      this.setScoringStrategyToolTipValue(value)

      this.scoringItems.map(item => {
        this[item] = false;
      });
      if (value !== 'FirstTo') {
        this.scoreDesc = true;
        this.scoreFirst = true;
      } else {
        this.scoreDesc = true;
        this.scoreFirst = false;
      }

      this.formData.strategies.strategyType = value;
      if (!this.scoringStrategyValue) this.scoringStrategyValue = 1;

      this.setScoringStrategy(this.scoringStrategyValue);
    },
    // AUTO START SWITCH
    swipeAutoStartHandler(direction) {
      this.autoStart = direction === 'right';
      this.updateConstraints(this.autoStart, 'autoStart');
    },
    autoStartUpdate(val) {
      this.autoStart = val;
      this.updateConstraints(val, 'autoStart');
    },
    // AUTO STOP SWITCH
    swipeAutoStopHandler(direction) {
      this.autoStop = direction === 'right';
      this.updateConstraints(this.autoStop, 'autoStop');
    },
    autoStopUpdate(val) {
      this.autoStop = val;
      this.updateConstraints(val, 'autoStop');
    },
    resetScoreConstraints() {
      this.formData.strategies.rankingStrategy.constraints = ['scoreDesc', 'scoreFirst'];
      this.formData.scoreDesc = true;
      this.formData.scoreFirst = true;
    },
    //  DEFAULT RANKING SWITCH
    swipeDefaultRankingHandler(direction) {
      this.defaultRanking = direction === 'right';
    },
    defaultRankingUpdate(val) {
      this.defaultRanking = val;
      if (!this.defaultRanking) {
        this.resetScoreConstraints();
      }
    },
    //  Scores Ranking Order  SWITCH
    swipeScoresRankingOrderHandler(direction) {
      this.scoreDesc = direction === 'right';
    },
    scoresRankingOrderUpdate(val) {
      this.scoreDesc = val;
    },
    //  TIME Ranking Order  SWITCH
    swipeTimeRankingOrderHandler(direction) {
      this.timeDesc = direction === 'right';
    },
    timeRankingOrderUpdate(val) {
      this.timeDesc = val;
    },
    // SCORE FIRST
    swipeScoreFirstHandler(direction) {
      this.scoreFirst = direction === 'right';
    },
    scoreFirstUpdate(val) {
      this.scoreFirst = val;
    },
    // Ignore Time
    swipeIgnoreTimeHandler(direction) {
      this.ignoreTime = direction === 'right';
    },
    ignoreTimeUpdate(val) {
      this.ignoreTime = val;
    },
    // Ignore Score
    swipeIgnoreScoreHandler(direction) {
      this.ignoreScore = direction === 'right';
    },
    ignoreScoreUpdate(val) {
      this.ignoreScore = val;
    },
    swipeExcludeIfGoalNotReached(direction) {
      this.excludeIfGoalNotReached = direction === 'right';
    },
    ignoreExcludeIfGoalNotReached(val) {
      this.excludeIfGoalNotReached = val;
    },
    handleUpdateScoringValue(val, strategyType) {
      const maxValue = strategyType === 'FirstTo' ? 4294967295678867 : 2147483647;
      if (!val || val < 1) {
        this.scoringStrategyValue = 1;
      } else if (val > maxValue) {
        this.scoringStrategyValue = maxValue;
      } else {
        this.scoringStrategyValue = parseInt(val);
      }
    },
    setScoringStrategy(val) {
      this.formData.strategies.scoringStrategy = {
        limitUpdatesTo: 0,
        sumBestXOf: 0,
        lastUpdateTimeStamp: 0,
        recordTimeWhenSumReaches: 0
      }
      switch (this.formData.strategies.strategyType) {
        case "SumBest": {
          this.formData.strategies.scoringStrategy.sumBestXOf = Number(val);
          break;
        }
        case "LimitedTo": {
          this.formData.strategies.scoringStrategy.limitUpdatesTo = Number(val);
          break;
        }
        case "FirstTo": {
          this.formData.strategies.scoringStrategy.recordTimeWhenSumReaches = Number(val);
          break;
        }
      }
    },
    setInvalidEndDate() {
      this.invalidEndDate = true;
    },
  },
  watch: {
    activeHover(val) {
      console.log('activeHover', val);
    },
    isStartDate(val) {
      this.starDataValidate = val;
    },
    isEndDate(val) {
      this.endDateValidate = val;
    },
    isStrategy(val) {
      this.scoringStrategyValidate = val;
    },
    autoStart(val) {
      this.toggleSwitch('autoStart', val);
    },
    autoStop(val) {
      this.toggleSwitch('autoStop', val);
    },
    defaultRanking(val) {
      if (val) {
        this.resetScoreConstraints();
      } else {
        this.formData.strategies.rankingStrategy.constraints = this.scoringItems.filter(item => {
          return this[item] === true
        })
      }
      this.toggleSwitch('defaultRanking', val);
    },
    // Scoring
    scoreDesc(val) {
      this.updateStrategyConstraints(val, 'scoreDesc');
      this.toggleSwitch('scoreDesc', val);
    },
    scoreFirst(val) {
      this.updateStrategyConstraints(val, 'scoreFirst');
      this.toggleSwitch('scoreFirst', val);
    },
    timeDesc(val) {
      this.updateStrategyConstraints(val, 'timeDesc');
      this.toggleSwitch('timeDesc', val);
    },
    ignoreTime(val) {
      this.updateStrategyConstraints(val, 'ignoreTime');
      this.toggleSwitch('ignoreTime', val);
    },
    ignoreScore(val) {
      this.updateStrategyConstraints(val, 'ignoreScore');
      this.toggleSwitch('ignoreScore', val);
    },
    excludeIfGoalNotReached(val) {
      this.updateStrategyConstraints(val, 'excludeIfGoalNotReached');
      this.toggleSwitch('excludeIfGoalNotReached', val);
    },
    scoringStrategyValue(val) {
      this.setScoringStrategy(val);
    },
    formData: {
      deep: true,
      handler(val) {
        val.scheduledStartDate ? this.starDataValidate = true : false;
        val.scheduledEndDate ? this.endDateValidate = true : false;
        // The strategy is only present in simple competitions
        if (this.creationType !== 'advanced') {
          val.strategies.strategyType ? this.scoringStrategyValidate = true : false;
        }

        this.$emit('updateSchedulingData', val);
        this.$emit('resetSchedulingValidate');
      }
    }
  },
  destroyed() {
    this.$emit('resetSchedulingValidate');
  }
}
</script>

<style lang="scss">
@import "~@coreui/coreui/scss/coreui";

.create-scheduling-step {
  .icon-validation-error {
    left: 20px;
  }
  .multiselect__option {
    white-space: normal;
    padding-bottom: 10px !important;
  }
  .schedule-date {
    display: flex;
    width: fit-content;

    .invalid-end-date {
      position: absolute;
      bottom: -17px;
      left: 15px;
      color: var(--zq-warn);
    }

      .schedule-endDate {
      position: relative;
      width: fit-content;

      .invalid-end-date {
        position: absolute;
        bottom: -5px;
        left: 10px;
        color: var(--zq-warn);
      }
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  .zq--data-picker.mx-datepicker {
    width: 374px;
  }
  .fa-question-circle-o {
    position: absolute;
    top: 0;
    right: -20px;
  }
  .settings-tag {
    .fa-question-circle-o {
      position: absolute;
      top: 0;
      right: -20px;
    }
    .search-icon {
      position: absolute;
      width: 30px !important;
      height: 47px !important;
      right: 15px;
      top: 1px;
      padding: 4px 8px;
      text-align: center;
    }
  }
}
</style>
