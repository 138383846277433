<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    :isCreateButtonVisible="isCreateButtonVisible"
    model="missions"
    @editRecord="editRecord"
  />
</template>
<script>
import { achievements } from '@/config/descriptions/achievements';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';

export default {
  name: 'MissionsTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'missions/missions',
          fields: 'missions/fields',
          sortableFields: 'missions/sortableFields',
          pages: 'missions/pages',
          loading: 'missions/loading',
          resultCount: 'missions/resultCount',
          totalRecords: 'missions/totalRecords',
          originalFields: 'missions/originalFields',
          searchableAllFields: 'missions/searchableAllFields',
          success: 'missions/success',
          message: 'missions/message',
        },
        actions: {
          fields: 'missions/handleFields',
          delete: 'achievements/handleDeleteAchievements',
          reset: 'missions/handleClearFilter',
          query: 'missions/handleGetMissionsByQuery',
        },
      },
      settings: {
        describe: { ...achievements },
        page: { ...achievementsTexts },
        statuses: ['All', 'Draft', 'Ready', 'Active', 'Finished', 'Cancelled', 'Deleted'],
        defaultStatus: 'Active',
      },
      allowedSpaces: ['first-space'],
      route: {
        create: 'CreateMission',
        // edit: 'EditMission',
        preview: 'PreviewMission',
      },
    };
  },
  computed: {
    currentSpace() {
      return localStorage.getItem('zq-space-name');
    },
    isCreateButtonVisible() {
      return true;
    }
  },
  methods: {
    editRecord(record) {
      this.$router.push({
        name: 'EditMission',
        params: {
          id: record.id,
        },
      });
    },
  }
};
</script>
