var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"leaderboard--actions-header"},[_c('CButton',{staticClass:"header-icon-buttons mr-3",attrs:{"size":"sm"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleReload.apply(null, arguments)}}},[_c('CIcon',{attrs:{"name":"cil-reload"}})],1)],1),_c('CRow',{staticClass:"mt-1"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq-card-table"},[_c('CCardBody',[_c('CDataTable',{staticClass:"zq--table",attrs:{"id":"leaderboardTableId","items":_vm.tableData,"fields":_vm.tableFields,"items-per-page":_vm.itemsPerPage,"sorter":{ external: true },"responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme,"loading":!_vm.ready,"sorter-value":_vm.sorterValue},on:{"pagination-change":_vm.itemsPerPageSelect,"page-change":_vm.paginationChange,"update:sorter-value":[_vm.handleSortBy,function($event){_vm.sorterValue=$event}],"update:sorterValue":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('TableLoader')]},proxy:true},{key:"members",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},_vm._l((item.members),function(member){return _c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showMember(member)}}},[_vm._v(" "+_vm._s(member.name)+" ")])}),0)]}},{key:"memberRefIds",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},_vm._l((item.members),function(member){return _c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showMember(member)}}},[_vm._v(" "+_vm._s(member.memberRefId)+" ")])}),0)]}},{key:"memberIds",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},_vm._l((item.members),function(member){return _c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showMember(member)}}},[_vm._v(" "+_vm._s(member.memberId)+" ")])}),0)]}},{key:"rewards",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getRewards(item))+" ")])]}}])}),_c('TableFooter',{key:_vm.footerReloadKey,attrs:{"page":_vm.page,"pages":_vm.getPages,"total":_vm.totalRecords,"itemsPerPage":_vm.itemsPerPage,"disabled":!_vm.ready},on:{"updatePagenation":_vm.paginationChange,"updateItemPerPage":_vm.itemsPerPageSelect}})],1)],1)],1)],1),_c('Modal',{attrs:{"modalShow":_vm.isModal,"messageGeneral":_vm.modalText,"title":_vm.modalTitle,"isSuccessButton":false},on:{"toggle-modal":function($event){_vm.isModal = false},"closeModal":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }