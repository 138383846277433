<template>
  <CRow class="edit-translations">
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="d-flex justify-content-between align-items-center">
            <strong class="title">Edit Translations</strong>
            <div class="message" v-if="!isTranslationsDataSaved">Data not saved!</div>
            <CButton
              class="action-create-button zq--responsive-button__common"
              type="submit"
              @click="updateTranslations"
            >
              Update Translations
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody v-if="ready">
          <!-- TABS -->
          <CRow class="mb-4">
            <CCol col="12" sm="11" class="d-flex flex-wrap align-items-center">
              <h6 class="mr-2">{{ texts.createPage.translations.titleSub }}</h6>
              <ul class="translation-tabs">
                <li v-for="(lang, index) in languages" :key="index" class="translation-tab-item">
                  <button
                    class="translation-tab-button"
                    :class="{'translation-tab-button__active': currentLang !== lang}"
                    @click="handleTranslation(lang)"
                  >
                    <span
                      v-if="isCompletelyFilled(lang)"
                      :class="{'check-icon__active': currentLang !== lang}"
                      class='exclaimIcon check-icon'
                    >
                     <i class="fa fa-check"></i>
                    </span>
                    <span
                      v-else-if="isPartiallyFilled(lang)"
                      :class="{'exclaimPartialCompleteIcon__active': currentLang !== lang}"
                      class='exclaimPartialCompleteIcon'
                    >
                      <i class="fa fa-exclamation"></i>
                    </span>
                    <span
                      v-else
                      :class="{'exclaim__active': currentLang !== lang}"
                      class="exclaimIcon"
                    >
                      <i class="fa fa-exclamation"></i>
                    </span>
                    <span :class="{'translation-tab-lang__active': currentLang !== lang}">{{ lang }}</span>
                  </button>
                </li>
              </ul>
            </CCol>
          </CRow>
          <!--  Form Content    -->
          <div v-if="currentLang">
            <ClPageToolbar :isLeftSide='false' @toggleEditorToolbar="handleToggleEditorToolbar"  />
            <div v-for="(val, key, index) in langData[currentLang]" :key="index">
              <h6 class="mb-2">{{ key }}</h6>
              <CRow class="mb-4 align-items-center" v-if="!editorFields.includes(key)">
                <CCol col="12" md="5" class="text-left pt-3">
                  <CInput
                    v-model="entityData[key]"
                    add-input-classes="col-sm-12"
                    disabled="true"
                  />
                </CCol>
                <CCol class="d-flex flex-column align-items-center text-center justify-content-center mb-2">
                  <CIcon name="cil-arrow-right" class='check-icon' />
                  <span>  to {{ currentLang }}</span>
                </CCol>
                <CCol col="12" md="5">
                  <CIInput
                    :value="
                      formData[languagesMap[currentLang]] && formData[languagesMap[currentLang]][key]
                      ? formData[languagesMap[currentLang]][key] :
                      ''
                    "
                    :name="key"
                    @input="handleInput"
                    add-input-classes="col-sm-12"
                    placeholder="Insert text here..."
                    :disabled="false"
                    :isTooltip="false"
                    :isLabel="false"
                    :isTranslation="true"
                    :xlContent="'12'"
                  />
                </CCol>
              </CRow>
              <CRow class="mb-4" v-else>
                <CCol col="12" md="5">
                  <ClEditor
                    :disabled="true"
                    :value-prop="entityData[key]"
                  />
                </CCol>
                <CCol class="d-flex flex-column align-items-center text-center mb-2">
                  <CIcon name="cil-arrow-right" class='check-icon' />
                  <span>  to {{ currentLang }}</span>
                </CCol>
                <CCol col="12" md="5">
                  <ClEditor
                    :name="key"
                    :value-prop="
                      formData[languagesMap[currentLang]] && formData[languagesMap[currentLang]][key]
                      ? formData[languagesMap[currentLang]][key] :
                      ''
                    "
                    :disabled="false"
                    :isToolbar="isToolbar"
                    :isTranslation="true"
                    @change="handleInput"
                  />
                </CCol>
              </CRow>
            </div>
          </div>
        </CCardBody>
        <PreviewSpiner v-else />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClEditor from "@/shared/components/formComponents/ClEditor";
import CIInput from '@/shared/components/formComponents/CIInput';
import PreviewSpiner from '@/shared/UI/Spiner';
import ClPageToolbar from "@/shared/components/ClPageToolbar";
import { achievementsTexts } from "@/config/pageTexts/achievements.json";
import { getLanguagesData, translationsTransform, translationsViewTransform } from "@/utils/translationsUtils";
import { cloneDeep, omit, isEqual } from "lodash";
import { mapActions, mapGetters } from "vuex";
import {pageConfig} from "@/config";

export default {
  name: "EditTranslations",
  components: {
    ClEditor,
    CIInput,
    PreviewSpiner,
    ClPageToolbar,
  },
  props: {
    entityData: Object,
    entityType: String,
    translatableFields: {
      type: Array,
      default: () => [],
    },
    translationsData: {
      type: Object,
      default: () => {},
    },
    notSavedTranslationsData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      ready: false,
      langData: {},
      currentLang: '',
      languages: [],
      languagesMap: {},
      languagesKeyIdMap: {},
      editorFields: ['description', 'termsAndConditions'],
      availableLanguages: [],
      formData: {},
      texts: {
        ...achievementsTexts
      },
      startData: {},
      isTranslationsDataSaved: true,
      translatableFieldsCount: 0,
      isToolbar: false,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
    }
  },
  mounted() {
    this.initialize();
  },
  destroyed() {
    if (!this.isTranslationsDataSaved) {
      this.$emit('notSavedTranslationsData', {notSavedData: this.formData, startData: this.startData});
    }
  },
  computed: {
    ...mapGetters('languages', {storeLanguages: 'languages'}),
  },
  methods: {
    ...mapActions('languages', ['handleGetLanguagesByQuery']),
    ...mapActions('translations', ['handleGetTranslationsByEntityId', 'handleUpdateTranslations']),
    async initialize() {
      if (this.storeLanguages.length) {
        this.availableLanguages = cloneDeep(this.storeLanguages);
      } else {
        this.availableLanguages = await this.handleGetLanguagesByQuery({
          queryRequest: {
            sortBy: this.sortBy,
            limit: 50,
            skip: 0
          }
        });
      }
      if (this.availableLanguages.length) {
        this.availableLanguages.forEach(lang => {
          this.languagesMap[lang.name] = lang.key;
          this.languagesKeyIdMap[lang.key] = lang.id;
        })
      }
      this.languages = this.getLanguages(this.availableLanguages);

      if (Object.keys(this.notSavedTranslationsData).length) {
        this.formData = cloneDeep(this.notSavedTranslationsData.notSavedData);
        this.startData = cloneDeep(this.notSavedTranslationsData.startData);
      } else {
        let data = await this.handleGetTranslationsByEntityId({refIdsArray: [this.entityData.id], limit: 99, skip: 0});
        if (data.length) {
          this.formData = translationsViewTransform(data, this.languages, true);
        } else {
          this.formData = getLanguagesData(this.languages);
        }
      }
      this.currentLang = this.availableLanguages[0].name;
      this.langData = this.getLengData(this.languages);
      this.prepareLangData();
      let diff = Object.values(this.languagesMap).filter(x => !Object.keys(this.formData).includes(x));
      if (diff.length) {
        diff.forEach(lang => {
          this.formData[lang] = {};
        })
      }
      if (!Object.keys(this.notSavedTranslationsData).length) {
        this.startData = cloneDeep(this.formData);
      }
      this.translatableFieldsCount = this.translatableFields.filter(item => item !== 'groupStageLabel').length;
      this.ready = true;
    },
    getLanguages(availableLanguages) {
      let languages = [];
      availableLanguages.forEach(language => languages.push(language.name))

      return languages;
    },
    getLengData(languages) {
      let lengData = {};
      languages.forEach(language => {
        lengData[language] = {}
      })

      return lengData;
    },
    handleTranslation(lang) {
      this.currentLang = lang;
    },
    prepareLangData() {
      for (let langKey in this.langData) {
        for (let entityKey in this.entityData) {
          if (this.translatableFields.includes(entityKey) && entityKey !== 'groupStageLabel') {
            this.langData[langKey][entityKey] = this.entityData[entityKey];
          }
        }
      }
      if (Object.keys(this.translationsData).length) {
        this.formData = cloneDeep(this.translationsData)
      }
    },
    handleInput(data) {
      this.fieldsHandler(data.value, data.name);
    },
    fieldsHandler(value, field) {
      if (!value) {
        this.formData[this.languagesMap[this.currentLang]] = omit(
          this.formData[this.languagesMap[this.currentLang]],
          field
        )
      } else {
        this.formData[this.languagesMap[this.currentLang]] = {
          ...this.formData[this.languagesMap[this.currentLang]],
          [field]: value.trim()
        }
      }
      if (!isEqual(this.startData, this.formData)) {
        this.isTranslationsDataSaved = false;
        this.$emit('isTranslationsDataSaved', false)
      } else {
        this.isTranslationsDataSaved = true;
        this.$emit('isTranslationsDataSaved', true)
      }
    },
    updateTranslations() {
      let data = cloneDeep(this.formData);
      delete data.id;
      let body = translationsTransform(data, this.languagesMap, true, this.languagesKeyIdMap, this.entityData.id, this.entityType);
      this.handleUpdateTranslations({updateTranslationRequestArray: body})
        .then(() => {
          this.isTranslationsDataSaved = true;
          this.$emit('isTranslationsDataSaved', true)
        })
    },
    isCompletelyFilled(lang) {
      const langObj = cloneDeep(this.formData[this.languagesMap[lang]]);
      delete langObj.id;

      const filledFields = Object.values(langObj).filter(item => item !== null);

      return this.translatableFieldsCount !== 0 && (filledFields.length === this.translatableFieldsCount);
    },
    isPartiallyFilled(lang) {
      const langObj = cloneDeep(this.formData[this.languagesMap[lang]]);
      delete langObj.id;

      const filledFields = Object.values(langObj).filter(item => item !== null);

      return filledFields.length < this.translatableFieldsCount && filledFields.length > 0;
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
  }
}
</script>

<style lang="scss">
.edit-translations {
  height: 100%;
  .col-xl-4 {
    width: 100%;
    max-width: 100%;
    flex: auto;
    padding-right: 0;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
    .card-header {
      background-color: transparent;
    }
  }
}
.exclaimIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  line-height: 16px;
  margin-right: 0.3em;
  background: var(--zq-el-grey-bg);
  color: white;
}

.exclaimPartialCompleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  line-height: 16px;
  margin-right: 0.3em;
  background: var(--zq-el-pink-bg);
  color: white;
  &__active {
    opacity: 0.5;
  }
}

.c-nxt-app__main-theme .translation-tab-button .check-icon {
  background: var(--zq-el-green-bg);
  &__active {
    background: lighten(#3bb54c, 15%);
  }
}
</style>
