<template>
  <div class="edit-mission">
    <CreateMissionsGraph
      v-if="graphData && missionsData"
      :graphData="graphData"
      :missionsData="missionsData"
      @handleShowMissionInfo="handleShowMissionInfo"
      :isEditMission="true"
    />
    <transition name="slide">
      <div v-if="isMissionEditing" class="mission-editing-block">
        <div class="mission-editing-block_header">
          <div class="mission-editing-block_header_title">Update {{ entityName }} step</div>
          <div class="mission-editing-block_header_actions">
            <CButton
              variant="outline"
              class="zq--custom-button zq--custom-button--responsive mr-4 mb-2"
              @click="showCancelModal"
            >
              {{ $t('buttons.cancel') }}
            </CButton>
          </div>
        </div>
        <div class="w-100 pr-3 pl-3">
          <StepsBlockComponent
            :steps="steps"
            :currentStep="currentStep"
            @changeStep="changeStep"
          />
        </div>
        <div class="w-100 pr-3 pl-3 pt-2 d-flex">
          <div v-if="currentStep === 0 && isReady" class="w-100 edit-settings-wrapper">
            <CCardHeader class="title-wrapper">
              <div class="mb-4 d-flex justify-content-between align-items-center">
                <strong class="title"> </strong>
                <div class="message" v-if="!isSettingsDataSaved">Data not saved!</div>
                <CButton
                  class="action-create-button zq--responsive-button__common"
                  type="submit"
                  @click="updateSettings"
                >
                  Update Settings
                </CButton>
              </div>
            </CCardHeader>
            <CRow>
              <CCol col="12">
                <CCard class="zq--wizard-card">
                  <CCardHeader @click="settingsCollapsed = !settingsCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">Edit Settings</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="settingsCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="settingsCollapsed" :duration="400">
                    <CCardBody>
                      <CForm
                        ref="updateAchievement"
                        v-on:submit.prevent="updateAchievement"
                        novalidate
                        class="update-achievement-form"
                      >
                        <FormBuilder
                          :list="formList"
                          :isCreateHeader="false"
                          @updated="updateFormData"
                          :page="{
                            title: texts.editPage.title,
                            info: descriptions,
                            mode: 'update',
                          }"
                          :isFilesPopup="true"
                        />
                      </CForm>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
            <CRow v-if="entrantsData">
              <CCol col="12">
                <CCard class="zq--wizard-card edit-members-card">
                  <CCardHeader @click="entrantsCollapsed = !entrantsCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">Edit Eligible members</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="entrantsCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="entrantsCollapsed" :duration="400">
                    <CCardBody>
                      <EditEntrants
                        :entrantsData="entrantsData"
                        :descriptions="entrantDescriptions"
                        :isValid="true"
                        :constraintsData="constraintsData"
                        @updateEntrantsData="updateEntrantsData"
                        @updateOptIn="updateOptIn"
                        type="achievement"
                      />
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CCard class="zq--wizard-card">
                  <CCardHeader @click="dependantOnCollapsed = !dependantOnCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">Edit DependantOn</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="dependantOnCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="dependantOnCollapsed" :duration="400">
                    <CCardBody>
                      <EditDependantOn
                        :dependantOnData="dependantOnData"
                        :isValid="true"
                        @updateDependantOnData="updateDependantOnData"
                        :isKey="false"
                      />
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
            <CRow v-if="schedulingData">
              <CCol col="12">
                <CCard class="zq--wizard-card scheduling">
                  <CCardHeader @click="schedulingCollapsed = !schedulingCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">Edit Scheduling</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="schedulingCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="schedulingCollapsed" :duration="400">
                    <CCardBody>
                      <EditScheduling
                        :schedulingData="schedulingData"
                        :isDisabled="isSchedulingDisabled"
                        @updateSchedulingData="updateSchedulingData"
                      />
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CCard class="zq--wizard-card">
                  <CCardHeader @click="pointsStrategyCollapsed = !pointsStrategyCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">Edit Points Strategy</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="pointsStrategyCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="pointsStrategyCollapsed" :duration="400">
                    <CCardBody>
                      <EditPointsStrategy
                        :strategies="strategies"
                        @updateStrategiesOperatorData="updateStrategiesOperatorData"
                        @updateStrategiesPointsValueUpperData="updateStrategiesPointsValueUpperData"
                        @updateStrategiesPointsValueData="updateStrategiesPointsValueData"
                      />
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
            <CRow v-if="productsData">
              <CCol col="12">
                <CCard class="zq--wizard-card">
                  <CCardHeader @click="productsCollapsed = !productsCollapsed">
                    <div class="d-flex justify-content-between">
                      <strong class="title">Edit Products</strong>
                      <CLink class="card-header-action btn-minimize">
                        <ClCardArrow :is-collapsed="productsCollapsed" />
                      </CLink>
                    </div>
                  </CCardHeader>
                  <CCollapse :show="productsCollapsed" :duration="400">
                    <CCardBody>
                      <AddProducts
                        @updateIsAllProductsInclude="updateIsAllProductsInclude"
                        @updateProductsData="getProductsData"
                        @updateCurrentTypes="updateProductsCurrentTypes"
                        :isValid="productsDataValidate"
                        :productsData="productsData"
                        :status="status"
                        :descriptions="productsDescriptions"
                      />
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </CCol>
            </CRow>
          </div>
          <PreviewSpiner v-if="!isReady" />
          <EditRules
            v-if="currentStep === 1"
            :entityId="entityId"
            :context="'achievement'"
            :notSavedRulesData="notSavedRulesData"
            @isRulesDataSaved="setRulesDataSaved"
            @notSavedRulesData="setNotSavedRulesData"
          />
          <div v-if="currentStep === 2" class="w-100 pt-3">
            <EditRewardsPopupView
              v-if="currentStep === 2"
              :entityId="entityId"
              :entityType="'Achievement'"
              :notSavedTranslationsData="notSavedTranslationsData"
              :notSavedRewardsData="notSavedRewardsData"
              @setRewardsDataSaved="setRewardsDataSaved"
              :isDisplayHeader="false"
              :texts="texts"
            />
          </div>
          <EditTranslations
            ref="editTranslations"
            v-if="currentStep === 3"
            :entityData="formData"
            :entityType="'Achievement'"
            :translatableFields="translatableFields"
            :translationsData="translationsData"
            :notSavedTranslationsData="notSavedTranslationsData"
            @isTranslationsDataSaved="setTranslationsDataSaved"
            @notSavedTranslationsData="setNotSavedTranslationsData"
          />
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import CreateMissionsGraph from '@/components/mission/CreateMissionsGraph';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import StepsBlockComponent from '@/components/mission/StepsBlockComponent';
import EditRewardsPopupView from '@/shared/components/supportModels/rewards/EditRewardsPopupView.vue';
import EditTranslations from '@/shared/components/supportModels/translations/EditTranslations.vue';
import EditRules from '@/shared/components/supportModels/rules/EditRules.vue';
import { cloneDeep, isEqual } from 'lodash';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import achievementFields from '@/generated/ziqni/store/modules/achievements/fields';
import { achievements } from '@/config/descriptions/achievements';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import { competitions } from '@/config/descriptions/competitions.json';
import { formConfig, pageConfig } from '@/config';
import PreviewSpiner from '@/shared/UI/Spiner.vue';
import AddProducts from '@/shared/components/supportModels/products/AddProducts.vue';
import EditEntrants from '@/shared/components/supportModels/entrants/EditEntrants.vue';
import EditDependantOn from '@/shared/components/supportModels/dependantOn/EditDependantOn.vue';
import EditPointsStrategy from '@/shared/components/supportModels/achievements/EditPointsStrategy.vue';
import ClCardArrow from '@/shared/UI/ClCardArrow.vue';
import EditScheduling from '@/shared/components/supportModels/scheduling/EditScheduling.vue';

export default {
  name: 'EditMission',
  components: {
    EditScheduling,
    ClCardArrow,
    EditPointsStrategy,
    EditDependantOn,
    EditEntrants,
    AddProducts,
    PreviewSpiner,
    EditRules,
    EditTranslations,
    EditRewardsPopupView,
    StepsBlockComponent,
    CreateMissionsGraph
  },
  data() {
    return {
      model: 'achievement',
      entityName: '',
      missionsData: [],
      graphData: null,
      entityId: null,
      isMissionEditing: false,
      steps: [
        { title: 'Settings', step: 0, },
        { title: 'Rules', step: 1, },
        { title: 'Rewards', step: 2, },
        { title: 'Translations', step: 3, }
      ],
      currentStep: 0,
      descriptions: {
        ...achievements.edit
      },
      texts: {
        ...achievementsTexts
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      formData: null,
      constraintsData: [],
      addConstraints: [],
      removeConstraints: [],
      page: pageConfig.page,
      formList: [],
      translatableFields: [],
      translationsData: {},
      modelFields: {
        ...achievementFields,
      },
      schedulingData: null,
      dependantOnData: null,
      entrantsData: null,
      rewardModels: ['achievement'],
      ruleModels: ['achievement'],
      dependantOnModels: ['achievement'],
      entrantsModels: ['achievement'],
      settingsCollapsed: true,
      dependantOnCollapsed: false,
      entrantsCollapsed: false,
      schedulingCollapsed: false,
      pointsStrategyCollapsed: false,
      strategies: null,
      isReady: false,
      isSettingsDataSaved: true,
      isRulesDataSaved: true,
      isRewardsDataSaved: true,
      isTranslationsDataSaved: true,
      isDependantOnDataSaved: true,
      isEntrantsDataSaved: true,
      notSavedText: 'Data not saved!',
      notSavedRulesData: {},
      notSavedTranslationsData: {},
      notSavedRewardsData: {},
      notSavedDependantOnData: {},
      notSavedEntrantsData: {},
      isSchedulingDisabled: false,
      productsCollapsed: false,
      productsDataValidate: null,
      productsData: {
        currentTypes: {},
        isAllProductsInclude: true,
        selectedData: [],
        formData: {
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: [],
          },
          must: [],
          mustNot: [],
          should: []
        },
      },
      status: null,
      rewardsData: [],
      transformedRewards: [],
      rewardsRerenderKey: 0
    };
  },
  mounted() {
    document.addEventListener('showMissionInfo', this.handleShowMissionInfo);
  },
  beforeDestroy() {
    document.removeEventListener('showMissionInfo', this.handleShowMissionInfo);
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('achievements', ['success', 'message', 'achievements', 'achievement']),
    ...mapGetters('actionTypes', ['success', 'message', 'actionType', 'actionTypes']),
    entrantDescriptions() {
      return {
        ...competitions.create.multi.entrants,
      };
    },
    productsDescriptions() {
      return {
        ...competitions.edit.competition.products.products,
      };
    },
  },
  watch: {
    currentStep: {
      deep: true,
      handler(val) {
        if (val === 2) {
          this.handleGetRewardsByQuery(
            {
              queryRequest: {
                should: [{
                  queryField: 'entityId',
                  queryValues: [this.entityId]
                }],
                shouldMatch: 1
              }
            }
          ).then(data => {
            this.updateRewardData(data);
            this.rewardsRerenderKey++;
          });
        }
      }
    }
  },
  methods: {
    ...mapActions('achievements', [
      'handleGetAchievements_item',
      'handleGetAchievementsByQuery',
      'handleCreateAchievements',
      'handleGetAchievementToClone',
      'handleUpdateAchievements',
      'handleGetAchievements'
    ]),
    ...mapActions('actionTypes', ['handleGetActionTypes_item', 'handleGetActionTypes']),
    ...mapActions('tags', ['handleGetTagsByQuery']),
    ...mapActions('rewards', ['handleGetRewardsByQuery']),
    async initialize() {
      await this.handleGetAchievements_item([this.$route.params.id], 1, 0).then((data) => {
        routerBreadcrumbs(this.$router.currentRoute, {
          name: data[0].name,
        });
      });

      const token = localStorage.getItem('vue-token');

      const path = 'https://api.ziqni.com/graphs';

      const entityGraphRequest = {
        ids: [this.$route.params.id],
        constraints: [],
        languageKey: '',
        includes: [],
        entityType: 'Achievement'
      };

      const { data } = await axios.post(path, entityGraphRequest, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ token }`
        }
      });

      const nodesIds = data.data.nodes.map(node => node.entityId);

      const subarray = [];
      for (let i = 0; i < Math.ceil(nodesIds.length / 20); i++) {
        subarray[i] = nodesIds.slice((i * 20), (i * 20) + 20);
      }

      const achievementsSubarray = [];

      for (let i = 0; i < subarray.length; i++) {
        const achievements = await this.handleGetAchievementsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'id',
                queryValues: subarray[i]
              }
            ],
            skip: 0,
            limit: 20
          }
        });

        for (const achievement of achievements) {
          const rewardData = await this.handleGetRewardsByQuery({
            queryRequest: {
              should: [
                {
                  queryField: 'entityId',
                  queryValues: [achievement.id]
                }
              ],
              shouldMatch: 1
            }
          });

          achievement.rewards = rewardData;
        }
        achievementsSubarray.push(achievements);
      }

      this.missionsData = achievementsSubarray.flat();

      this.graphData = data.data;
    },
    handleShowMissionInfo(event) {
      const { missionId } = event.detail;
      this.entityId = missionId;
      this.isMissionEditing = true;
      this.getAchievement();
    },
    showCancelModal() {
      this.isMissionEditing = false;
      this.entityId = null;
      this.entityName = '';
      this.currentStep = 0;
    },
    changeStep(targetStep) {
      this.currentStep = targetStep;
    },
    getAchievement() {
      this.handleGetAchievements_item([this.entityId], 1, 0)
        .then(async data => {
          this.entityName = data[0].name;
          this.strategies = cloneDeep(data[0].strategies);

          if (data[0].achievementDependencies) {
            this.dependantOnData = data[0].achievementDependencies;
          } else {
            this.dependantOnData = {
              must: [],
              mustNot: [],
              should: [],
              shouldMatchAtLeast: 0,
            };
          }

          this.entrantsData = data[0].memberTagsFilter;
          let actionTypesIds = [];
          if (data[0].actionTypeAdjustmentFactors) {
            actionTypesIds = data[0].actionTypeAdjustmentFactors.map((item) => {
              return item.actionTypeId;
            });
          }

          this.constraintsData = data[0].constraints;

          this.query = actionTypesIds.length ? { 'id': actionTypesIds.join() } : []; // just query for the ids!

          this.handleGetActionTypes_item(actionTypesIds);
          this.formList = fieldHelpers.prepareEditFormList(
            achievementFields,
            this.texts.editPage,
            this.descriptions,
            this.achievement
          );

          if (Object.keys(data[0]).includes('scheduling')) {
            this.schedulingData = cloneDeep(data[0].scheduling);
            if (this.schedulingData.startDate) {
              this.schedulingData.startDate = new Date(this.schedulingData.startDate);
            }
            if (this.schedulingData.endDate) {
              this.schedulingData.endDate = new Date(this.schedulingData.endDate);
            }
          }

          if (Object.keys(data[0]).includes('dependantOn')) {
            if (data[0].dependantOn) {
              this.dependantOnData = cloneDeep(data[0].dependantOn);
            } else {
              this.dependantOnData = {
                dependantOn: {
                  must: [],
                  mustNot: [],
                  should: [],
                  shouldMatchAtLeast: 1,
                },
                entityType: 'Achievement',
              };
            }
          }

          if (['Active', 'Finished', 'Cancelled', 'Deleted'].includes(data[0].status)) {
            this.isSchedulingDisabled = true;
          }

          this.status = data[0].status;

          if (typeof data[0].productTagsFilter === 'object' && Object.keys(data[0].productTagsFilter).length) {
            this.productsData.formData.dependantOn.must = data[0].productTagsFilter.must ?? [];
            this.productsData.formData.dependantOn.should = data[0].productTagsFilter.should ?? [];
            this.productsData.formData.dependantOn.mustNot = data[0].productTagsFilter.mustNot ?? [];

            const productTagsKeys = [
              ...this.productsData.formData.dependantOn.must,
              ...this.productsData.formData.dependantOn.should,
              ...this.productsData.formData.dependantOn.mustNot,
            ];

            const productTags = await this.handleGetTagsByQuery({
              queryRequest: {
                must: [
                  {
                    queryField: 'key',
                    queryValues: productTagsKeys
                  }
                ]
              }
            });
            let currentTypes = {};
            for (const key in data[0].productTagsFilter) {
              if (data[0].productTagsFilter[key] && data[0].productTagsFilter[key].length) {
                data[0].productTagsFilter[key].forEach(tag => {
                  currentTypes[tag] = key;
                });
              }
            }
            this.productsData = {
              isAllProductsInclude: data[0].constraints.includes('allProducts'),
              selectedData: productTags,
              formData: {
                shouldMatchAtLeast: data[0].productTagsFilter.shouldMatchAtLeast,
                dependantOn: {
                  must: data[0].productTagsFilter.must ?? [],
                  mustNot: data[0].productTagsFilter.mustNot ?? [],
                  should: data[0].productTagsFilter.should ?? []
                }
              },
              currentTypes: currentTypes,
            };
          } else {
            this.productsData = {
              isAllProductsInclude: data[0].constraints.includes('allProducts'),
              selectedData: data[0].products,
              formData: {
                shouldMatchAtLeast: null,
                dependantOn: {
                  must: [],
                  mustNot: [],
                  should: []
                }
              }
            };
          }

          this.isReady = true;
        });
      this.translatableFields = achievementFields.baseFields.translatableFields;
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          transformedRewards.push(reward);
        });
      }
      this.transformedRewards = transformedRewards;
    },
    setRulesDataSaved(val) {
      this.isRulesDataSaved = val;
    },
    setRewardsDataSaved(val) {
      this.isRewardsDataSaved = val;
      setTimeout(() => {
        this.initialize();
      }, 1000);
    },
    setTranslationsDataSaved(val) {
      this.isTranslationsDataSaved = val;
    },
    setNotSavedRulesData(obj) {
      this.notSavedRulesData = obj;
    },
    setNotSavedTranslationsData(obj) {
      this.notSavedTranslationsData = obj;
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
    updateEntrantsData(val) {
      const entrantsDataObj = {
        must: val.formData.must,
        mustNot: val.formData.mustNot,
        should: val.formData.should,
        shouldMatchAtLeast: val.formData.shouldMatchAtLeast
      };
      this.entrantsData = entrantsDataObj;

      this.formData.memberTagsFilter = entrantsDataObj;

      this.isSettingsDataSaved = false;
    },
    updateOptIn(val) {
      if (val) {
        if (!this.constraintsData.includes('optinRequiredForEntrants')) {
          if (!this.addConstraints.includes('optinRequiredForEntrants')) {
            this.addConstraints.push('optinRequiredForEntrants');
            let idx = this.removeConstraints.findIndex(c => c === 'optinRequiredForEntrants');
            if (idx !== -1) {
              this.removeConstraints.splice(idx, 1);
            }
          }
        }
      } else {
        if (this.constraintsData.includes('optinRequiredForEntrants')) {
          if (!this.removeConstraints.includes('optinRequiredForEntrants')) {
            this.removeConstraints.push('optinRequiredForEntrants');
            let idx = this.addConstraints.findIndex(c => c === 'optinRequiredForEntrants');
            if (idx !== -1) {
              this.addConstraints.splice(idx, 1);
            }
          }
        }
      }
    },
    updateDependantOnData(val) {
      if (!isEqual(this.dependantOnData.dependantOn, val.formData.dependantOn)) {
        let selectedIds = [];
        val.selectedData.forEach(item => selectedIds.push(item.id));
        this.dependantOnData = cloneDeep(val.formData.dependantOn);
        this.dependantOnData.must = this.dependantOnData.must.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.mustNot = this.dependantOnData.mustNot.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.should = this.dependantOnData.should.filter(item => {
          return selectedIds.includes(item);
        });
        this.dependantOnData.entityType = val.formData.entityType;
        this.formData.achievementDependencies = this.dependantOnData;
        this.isSettingsDataSaved = false;
      }
    },
    updateSchedulingData(val) {
      if (!this.formData) return;
      this.schedulingData = val;
      this.formData.scheduling = val;
      this.isSettingsDataSaved = false;
    },
    updateStrategiesOperatorData(val) {
      this.strategies.pointsStrategy.operator = val.key;
    },
    updateStrategiesPointsValueUpperData(val) {
      this.strategies.pointsStrategy.pointsValueUpper = val;
    },
    updateStrategiesPointsValueData(val) {
      this.strategies.pointsStrategy.pointsValue = val;
    },
    getProductsData(val) {
      this.productsData = val;
    },
    getProductTagsFilterTagsFilter() {
      return {
        must: this.productsData.formData.dependantOn.must.length ? this.productsData.formData.dependantOn.must : null,
        mustNot: this.productsData.formData.dependantOn.mustNot.length ? this.productsData.formData.dependantOn.mustNot : null,
        should: this.productsData.formData.dependantOn.should.length ? this.productsData.formData.dependantOn.should : null,
        shouldMatchAtLeast: this.productsData.formData.dependantOn.shouldMatchAtLeast,
      };
    },
    async getProductIds() {
      let productIds = [];

      if (this.productsData.selectedData.length && Object.hasOwn(this.productsData.selectedData[0], 'productRefId')) {
        this.productsData.selectedData.forEach(product => {
          productIds.push(product.id);
        });
      }

      return productIds;
    },
    updateProductsCurrentTypes(val) {
      this.productsData.currentTypes = val;
    },
    updateIsAllProductsInclude(val) {
      this.productsData.isAllProductsInclude = val;
    },
    async updateSettings() {
      delete this.formData.statusCode;
      delete this.formData.status;
      delete this.formData.achievementLiveStatus;
      delete this.formData.spaceName;
      delete this.formData.created;
      delete this.formData.productRefId;
      delete this.formData.rewardType;
      delete this.formData.constraints;
      delete this.formData.memberRefId;
      delete this.formData.key;
      delete this.formData.dependantOn;
      delete this.formData.products;

      if (this.removeConstraints.length) this.formData.removeConstraints = this.removeConstraints;
      if (this.addConstraints.length) this.formData.addConstraints = this.addConstraints;
      this.formData.strategies = this.strategies;

      // Products
      if (this.productsData.isAllProductsInclude) {
        if (!this.formData.addConstraints) this.formData.addConstraints = [];
        this.formData.addConstraints.push('allProducts');
      } else {
        if (!this.formData.removeConstraints) this.formData.removeConstraints = [];
        this.formData.removeConstraints.push('allProducts');
        this.formData.productIds = await this.getProductIds();
        if (!this.formData.productIds.length) {
          this.formData.productTagsFilter = this.getProductTagsFilterTagsFilter();
        } else {
          delete this.formData.productTagsFilter;
        }
      }
      if (!this.formData.icon || this.formData.icon.trim() === '') {
        this.formData.icon = null;
      }

      const body = [this.formData];

      this.handleUpdateAchievements({ updateAchievementRequestArray: body })
        .then(() => {
          this.isSettingsDataSaved = true;
          setTimeout(() => {
            this.initialize();
          }, 1000);

        });
    }
  }
};
</script>

<style scoped lang="scss">
.c-nxt-app__main-theme .zq--wizard-card.card {
  background-color: #F3F3F3;
}

.c-nxt-app__main-theme .zq--wizard-card .card-body {
  border-top: none !important;
  margin: 0 5px !important;
}

.form-content {
  border-top: none !important;
}

.edit-mission {
  width: 100%;
  height: calc(100vh - 110px);

  .mission-editing-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    overflow: hidden;
    top: 70px;
    right: 0;
    z-index: 1000;
    background-color: #f1f2f4;
    width: 60%;
    height: 88%;
    border-radius: 20px 0 0 20px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2),
    10px 10px 20px rgba(0, 0, 0, 0.2),
    15px 15px 30px rgba(0, 0, 0, 0);

    &_header {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      &_title {
        color: rgba(0, 0, 0, 0.6);
        font-size: 22px;
        font-weight: 400;
        line-height: 33px;
        text-align: center;

      }

      &_actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .edit-settings-wrapper {
      max-height: 700px;
      overflow-y: auto;

      header.card-header.title-wrapper {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: #f1f2f4;
        border: none;

        > div {
          margin-bottom: 0 !important;
        }
      }

      .card {
        .card-header {
          border: none;

        }

        .card-body {
          padding: 10px 0 10px;

        }
      }
    }

    .edit-translations,
    .rewards,
    .edit-rules {
      width: 100%;

      .message {
        color: var(--zq-warn) !important;
      }
    }

    .rewards {
      .rewards-card-header {
        display: none;
      }
    }
  }

  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter-active {
    transition: transform 0.5s ease-in-out;
  }

  .slide-enter-to {
    transform: translateX(0);
  }

  .slide-leave {
    transform: translateX(0);
  }

  .slide-leave-active {
    transition: transform 0.5s ease-in-out;
  }

  .slide-leave-to {
    transform: translateX(100%);
  }

  .message {
    color: var(--zq-warn) !important;
  }

  .notSaved {
    color: var(--zq-warn) !important;
  }

  .form-content {
    border-top: 0;
  }
}

</style>
