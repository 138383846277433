<template>
  <CRow class="rules-summary">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="collapse">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.createPage.summary.rules.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody class="overflow-auto rules-summary-card">
            <RulesEngine
              v-if="isReady && Object.keys(viewRulesData).length !== 0"
              :query="viewRulesData"
              :rules="rules"
              :routines="routines"
              :isJsonView="false"
              :isPreview="true"
            />
            <div class="link-wrapper" v-else-if="isReady && Object.keys(viewRulesData).length === 0">
              <span class="link" @click="goToContestsTab">
                {{ texts.noRules }}
              </span>
            </div>
            <PreviewSpiner v-else/>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardArrow from '@/shared/UI/ClCardArrow';
import { achievementsTexts } from "@/config/pageTexts/achievements.json";
import { defaultRoutines } from "@/helpers/rules/routines";
import { cloneDeep } from "lodash";
import { mapActions } from "vuex";
import PreviewSpiner from '@/shared/UI/Spiner';

export default {
  name: 'ViewRules',
  components: {
    ClCardArrow,
    PreviewSpiner,
  },
  props: {
    rulesData: {
      type: Object,
      default() {
        return {}
      },
    },
    entityId: {
      type: String,
      default: '',
    },
    context: {
      type: String,
      default: 'competition',
    }
  },
  data() {
    return {
      viewRulesData: {},
      isReady: false,
      texts: {
        ...achievementsTexts
      },
      routines: defaultRoutines,
      rules: {},
      basicIsCollapsed: false,
    }
  },
  methods: {
    ...mapActions('rules', ['handleGetRulesByQuery', 'handleGetRuleSchemas']),
    goToContestsTab() {
      this.$emit('goToContestsTab', 1);
      this.basicIsCollapsed = false;
    },
    async collapse() {
      this.basicIsCollapsed = !this.basicIsCollapsed;
      if (Object.keys(this.rulesData).length) {
        this.viewRulesData = cloneDeep(this.rulesData);
      }

      if (this.basicIsCollapsed && Object.keys(this.rules).length === 0) {
        await this.handleGetRuleSchemas({context: this.context})
          .then((items) => {
            const schemas = cloneDeep(items[0]);
            const pointsIdx = schemas.actions.findIndex(item => item.action === 'contest.points');

            if (pointsIdx !== -1) {
              schemas.actions = schemas.actions.filter(item => item.action === 'contest.points');
              // const points = schemas.actions[pointsIdx];
              // schemas.actions.splice(pointsIdx, 1);
              // schemas.actions.unshift(points);
            }

            this.rules = cloneDeep(schemas);

            const routines = cloneDeep(schemas.routines);
            if (routines && routines.length) {
              this.routines = routines.map(routine => {
                if (routine.arguments[0].macros && routine.arguments[0].macros.length) {
                  const macros = [];
                  for (let i = 0; i < routine.arguments[0].macros.length; i++) {
                    macros.push({ scope: '$variable', value: routine.arguments[0].macros[i] });
                  }
                  routine.arguments[0].macros = macros;
                }

                if (routine.parameter.macros && routine.parameter.macros.length) {
                  const macros = [];
                  for (let i = 0; i < routine.parameter.macros.length; i++) {
                    macros.push({ scope: '$variable', value: routine.parameter.macros[i] });
                  }
                  routine.parameter.macros = macros;
                }

                return routine;
              });
            }
          });
      }
      if (this.basicIsCollapsed && Object.keys(this.viewRulesData).length === 0) {
        await this.handleGetRulesByQuery(
          {
            queryRequest: {
              should: [{
                queryField: 'entityId',
                queryValues: [this.entityId]
              }],
              shouldMatch: 1
            }
          }
        ).then(data => {
            if (data && data.length) {
              data.forEach(action => {
                this.viewRulesData[action.action] = action.rules;
              })
            }
          });
      }
      this.isReady = true;
    },
    replaceInput() {
      const wrapInputElements = document.querySelectorAll('.rew-routine-input-wrapp');
      const wrapInputExpressionElements = document.querySelectorAll('.rew-expression-input');

      wrapInputElements.forEach(wrapInputElement => {
        const inputElement = wrapInputElement.querySelector('.rew-routine-input');
        const selectElement = wrapInputElement.querySelector('.v-select');
        const selectedElement = selectElement?.querySelector('.vs__selected');
        const textValue = inputElement.value
          ? inputElement.value
          : (selectedElement ? selectedElement.innerHTML : '');

        if (selectElement) selectElement.innerHTML = '';

        const divElement = document.createElement('div');
        divElement.setAttribute('class', inputElement.getAttribute('class'));
        divElement.innerHTML = textValue;
        divElement.classList.add('rew-routine-input-replaced');

        inputElement.replaceWith(divElement);
      })
      wrapInputExpressionElements.forEach(inputElement => {
        const textValue = inputElement.value;
        const divElement = document.createElement('div');
        divElement.setAttribute('class', inputElement.getAttribute('class'));
        divElement.innerHTML = textValue;
        divElement.classList.add('rew-routine-input-replaced');

        inputElement.replaceWith(divElement);
      })
    }
  },
  watch: {
    rules(val) {
      if (val) {
        setTimeout(() => {
          this.replaceInput();
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss">
.link-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .link {
    padding: 50px 0;
  }
}

.rew-routine-input-wrapp {
  max-width: 300px;

  .rew-routine-input-replaced {
    padding-top: 3px;
    margin-top: 5px;
    display: inline-block;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rew-expression-input.rew-routine-input-replaced {
  width: fit-content;
  display: flex;
  align-items: center;
}

.rules-summary {
  &-card.card-body {
    min-height: 200px;
  }
  .tabs-component-panels {
    margin-top: -1px;
  }
  .rew-routine-select .vs__search {
    border: 0;
  }
  .rew-expression-select .vs__search {
    display: none;
  }
}
</style>