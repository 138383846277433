var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{class:_vm.className,attrs:{"title":_vm.title,"color":"danger","centered":"","show":_vm.modal,"closeOnBackdrop":_vm.closeOnBackdrop},on:{"update:show":[function($event){_vm.modal=$event},_vm.updateModal]},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title))]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":_vm.closeByIcon}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]},proxy:true}])},[(_vm.toolTipText && _vm.toolTipText.length)?_c('div',{staticClass:"zq-code-edit--tooltip",style:({
      position: 'absolute',
      right: ("calc(100% - " + ((_vm.title.length * 10 + 85)) + "px)"),
      marginRight: '20px'
    })},[_c('IconWithTooltip',{staticClass:"mt-2",attrs:{"text":_vm.toolTipText}})],1):_vm._e(),(_vm.subTitle)?_c('div',{staticClass:"zq--modal--subtitle"},[_c('span',[_vm._v(_vm._s(_vm.subTitle))])]):_vm._e(),(_vm.dangerText)?_c('div',{staticClass:"zq--modal--danger"},[_c('span',[_vm._v(_vm._s(_vm.dangerText))])]):_vm._e(),_c('div',{staticClass:"zq--modal--message mb-3",domProps:{"innerHTML":_vm._s(_vm.messageGeneral)}}),_vm._t("body"),_c('div',{staticClass:"zq--modal--footer-content",attrs:{"slot":"footer"},slot:"footer"},[(_vm.isCloseButton)?_c('CButton',{staticClass:"ml-2",class:{
        'zq--responsive-button__common': _vm.cancelBtnLabel === 'Create',
        'zq--custom-button': _vm.cancelBtnLabel !== 'Create'
      },attrs:{"color":_vm.colorBtn('secondary')},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.cancelBtnLabel)+" ")]):_vm._e(),(_vm.isSuccessButton)?_c('CButton',{staticClass:"ml-3 zq--responsive-button__common",attrs:{"color":_vm.colorBtn('danger')},on:{"click":_vm.doFunction}},[_vm._v(" "+_vm._s(_vm.successBtnLabel)+" ")]):_vm._e()],1),(_vm.loading)?_vm._t("loading"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }