<template>
  <div class="rewards">
    <CCardHeader v-if="isDisplayHeader">
      <div class="d-flex justify-content-between align-items-center">
        <strong class="title">Edit Rewards</strong>
        <div class="message" v-if="!isRewardsDataSaved">Data not saved!</div>
        <CButton
          v-if="isUpdateButtonVisible"
          class="action-create-button zq--responsive-button__common"
          type="submit"
          @click="updateRewardsRequest"
        >
          Update Rewards
        </CButton>
      </div>
    </CCardHeader>
    <!--  Create Section   -->
    <CreateReward
      v-if="currentPage.page === 'reward creation'"
      @createReward="createReward"
      @cancelReward="cancelReward"
      :pageNumber="pageNumber"
      :isRewardRankField="isRewardRankField"
      :rewardData="templateRewardData"
      @setPage="setPage"
      :isCreate="isCreate"
    />
    <!-- Add From Template   -->
    <AddFromTemplate
      v-if="currentPage.page === 'addFromTemplate'"
      @updateRewardsData="updateRewardsData"
      :rewardPropsData="selectedRewards"
      :pageNumber="pageNumber"
      @setPage="setPage"
      :entityType="entityType"
    />
    <!--  Table Section  -->
    <TableRewards
      @createNewReward="createNewReward"
      @addFromTemplate="addFromTemplate"
      @updateRewardsData="updateRewardData"
      :rewardData="editRewardsData"
      v-on:updateRewards="updateRewards"
      @setPage="setPage"
      v-if="isReady && currentPage.page === 'rewards'"
      :disabled="isFieldDisabled"
      :pageNumber="pageNumber"
      :isPageNumber="false"
      :loading="loading"
      :chosenRewards="selectedRewards"
      :isUpdate="true"
    />
    <PreviewSpiner v-if="!isReady" />
    <PreviewReward
      v-if="currentPage.page === 'preview reward'"
      backPathOrigin="rewards"
      :rewardData="currentPage.data"
      :pageNumber="pageNumber"
      @setPage="setPage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CreateReward from './components/CreateReward';
import TableRewards from './components/TableRewards';
import AddFromTemplate from './components/AddFromTemplate';
import PreviewReward from './components/PreviewReward';
import { pageConfig } from '@/config';
import { isCompFieldDisabled, isAchFieldDisabled } from '@/utils/validationUtils';
import { cloneDeep, delay, isEqual } from 'lodash';
import PreviewSpiner from '@/shared/UI/Spiner';

export default {
  name: 'EditRewards',
  components: {
    CreateReward,
    TableRewards,
    AddFromTemplate,
    PreviewReward,
    PreviewSpiner,
  },
  props: {
    entityId: {
      type: String,
      default: '',
    },
    entityType: {
      type: String,
      default: '',
    },
    isRewardRankField: {
      type: Boolean,
      default: true
    },
    pageNumber: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: 'Ach'
    },
    status: {
      type: String,
      default: 'Ready'
    },
    isDisplayHeader: {
      type: Boolean,
      default: true
    },
    notSavedRewardsData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      query: {
        'should': [
          {
            'queryFields': ['tags'], 'queryValue': 'template'
          }
        ]
      },
      sortBy: pageConfig.sortBy,
      itemsPerPage: pageConfig.itemsPerPage,
      page: pageConfig.page,
      currentPage: { page: 'rewards', data: {} },
      editRewardsData: [],
      selectedRewards: [],
      loading: false,
      isReady: false,
      isSelectTableVisible: false,
      isRewardsDataSaved: true,
      templateRewardData: {},
      isUpdateButtonVisible: false,
      isCreate: true
    };
  },
  computed: {
    ...mapGetters('rewards', ['rewards']),
    isFieldDisabled() {
      if (this.pageType === 'Ach') {
        return isAchFieldDisabled(this.status);
      }
      return isCompFieldDisabled(this.status);
    },
    previewFileBreadcrumbs() {
      if (this.currentPage.data.from) {
        return ['Rewards', 'Reward creation', 'Preview File'];
      } else {
        return ['Rewards', 'Reward creation', 'Repositories', 'Files', 'Preview File'];
      }
    },
  },
  created() {
    this.initialize();
  },
  destroyed() {
    if (!this.isRewardsDataSaved) {
      this.$emit('notSavedRewardsData', { editRewardsData: this.editRewardsData, startData: this.startData });
    }
  },
  methods: {
    ...mapActions('rewards', [
      'handleFields',
      'handleGetRewards',
      'handleGetRewardsByQuery',
      'handleUpdateRewards',
      'handleCreateRewards',
      'handleDeleteRewardsByQuery'
    ]),
    async initialize() {
      await this.getRewards();
      if (Object.keys(this.notSavedRewardsData).length) {
        this.editRewardsData = cloneDeep(this.notSavedRewardsData.editRewardsData);
        this.selectedRewards = cloneDeep(this.notSavedRewardsData.editRewardsData);
        this.startData = cloneDeep(this.notSavedRewardsData.startData);
      } else {
        await this.handleGetRewardsByQuery(
          {
            queryRequest: {
              should: [{
                queryField: 'entityId',
                queryValues: [this.entityId]
              }],
              shouldMatch: 1
            }
          }
        ).then(data => {
          this.editRewardsData = cloneDeep(data);
          this.selectedRewards = cloneDeep(data);
          this.startData = cloneDeep(data);
        });
      }
      this.isReady = true;
      this.handleFields();
      if (!isEqual(this.editRewardsData, this.startData)) {
        this.isRewardsDataSaved = false;
        this.$emit('isRewardsDataSaved', false);
      }
    },
    async getRewards() {
      await this.handleGetRewards({ idArray: [] });
    },
    createNewReward() {
      this.isRewardsDataSaved = true;
      this.isUpdateButtonVisible = false;
      this.currentPage.page = 'reward creation';
    },
    addReward(selectedRewards) {
      this.selectedRewards = selectedRewards.rewards;
      this.$emit('updateRewardData', selectedRewards);
    },
    async createReward(val) {
      this.currentPage.page = 'rewards';
      this.templateRewardData = {};
      this.isCreate = true;
      if (val.id && !val.id.includes('nxt_', 0)) {
        const data = [
          {
            id: val.id,
            metadata: val.metadata,
            entityType: this.entityType,
            entityId: this.entityId,
            name: val.name,
            description: val.description,
            rewardRank: val.rewardRank,
            rewardValue: val.rewardValue,
            icon: val.icon,
            issueLimit: val.issueLimit,
            delay: val.delay,
            pointInTime: val.pointInTime,
            period: val.period,
            translations: [],
            rewardTypeId: val.rewardTypeId,
          }
        ];
        if (!data[0].icon) delete data[0].icon;

        this.handleUpdateRewards({updateEntityRewardRequestArray: data}).then(() => {
          this.loading = true;
          delay(async () => {
            await this.handleGetRewardsByQuery(
              {
                queryRequest: {
                  should: [{
                    queryField: 'entityId',
                    queryValues: [this.entityId]
                  }],
                  shouldMatch: 1
                }
              }
            ).then(data => {
              this.editRewardsData = cloneDeep(data);
              this.selectedRewards = cloneDeep(data);
              this.startData = cloneDeep(data);
            });
            this.loading = false;
          }, 2500);
        });
      } else {
        const data = [
          {
            metadata: val.metadata,
            entityType: this.entityType,
            entityId: this.entityId,
            name: val.name,
            description: val.description,
            rewardRank: val.rewardRank,
            rewardValue: val.rewardValue,
            icon: val.icon,
            issueLimit: val.issueLimit,
            delay: val.delay,
            pointInTime: val.pointInTime,
            period: val.period,
            translations: [],
            constraints: val.isMemberAcknowledgmentRequired ? ['memberAcknowledgmentRequired'] : [],
            rewardTypeId: val.rewardTypeId,
          }
        ];

        await this.handleCreateRewards({createEntityRewardRequestArray: data})
          .then(() => {
            this.loading = true;
            delay(async () => {
              await this.handleGetRewardsByQuery(
                {
                  queryRequest: {
                    should: [{
                      queryField: 'entityId',
                      queryValues: [this.entityId]
                    }],
                    shouldMatch: 1
                  }
                }
              ).then(data => {
                this.editRewardsData = cloneDeep(data);
                this.selectedRewards = cloneDeep(data);
                this.startData = cloneDeep(data);
              });
              this.loading = false;
            }, 2500);
          });
      }
      this.$emit('isRewardsDataSaved', true);
    },
    addFromTemplate() {
      this.isRewardsDataSaved = true;
      this.isUpdateButtonVisible = false;
      this.currentPage.page = 'addFromTemplate';
    },
    cancelReward() {
      this.editRewardsData = this.startData;
      this.templateRewardData = {};
      this.isCreate = true;
      this.currentPage.page = 'rewards';
    },
    cancelEditReward() {
      this.currentPage = 'rewards';
    },
    updateRewards(val) {
      this.editRewardsData = val;
      if (this.startData.length > val.length) {
        this.isRewardsDataSaved = false;
        this.isUpdateButtonVisible = true;
      }
    },
    setPage(val) {
      this.currentPage = val;
    },
    updateRewardsData(val) {
      this.templateRewardData = val[0];
    },
    updateRewardData(val) {
      this.isCreate = false;
      this.templateRewardData = val[0];
    },
    addIcon(val) {
      this.$emit('addRewardIcon', val);
      this.currentPage = { page: 'reward creation', data: { id: val } };
    },
    updateRewardsRequest() {
      if (!isEqual(this.editRewardsData, this.startData)) {
        const difference = this.startData.filter(item1 => !this.editRewardsData.some(item2 => item2.id === item1.id));
        const differenceEntityIdIds = difference.map(item => item.entityId);
        const rewardIds = difference.map(item => item.id);

        this.editRewardsData.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.created;
          delete reward.customFields;
          delete reward.metadata;
          delete reward.spaceName;
          delete reward.rewardType;
          delete reward.constraints;
        });

        const queryRequest = {
          must: [
            {
              queryField: 'entityId',
              queryValues: [differenceEntityIdIds[0]]
            },
            {
              queryField: 'id',
              queryValues: rewardIds
            }
          ]
        };

        this.handleDeleteRewardsByQuery({ queryRequest })
          .then(() => {
            this.$emit('isRewardsDataSaved', true);
            this.isRewardsDataSaved = true;
            this.isUpdateButtonVisible = false;
          });
      }
    }
  },
};
</script>

<style lang="scss">
.rewards {
  height: 100%;
}
</style>
