<template>
  <div class="w-100">
    <div :class="isColumn ? 'flex-column w-100' : 'd-flex w-100' " class="tag-field-wrapper">
      <div :class="isColumn ? '' : 'pl-0 col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2'" class="label">
        <span class="tag-label-text">{{ label }}</span>
      </div>
      <div class="d-flex align-items-center pl-0 tag-field">
        <multiselect
          v-model="selectedTagObjects"
          :options="tagOptions"
          @search-change="onInput"
          :taggable="true"
          :multiple="true"
          :hide-selected="true"
          @tag="addTag"
          :selectLabel="label"
          placeholder="Type here..."
          :disabled="disabled"
          label="name"
          track-by="key"
          class="tags-multi-select custom-multi-select zq--multiselect zq--multiselect__green"
          :class="isColumn ? '' : 'col-11 col-sm-11 col-md-11 col-lg-11 col-xl-6'"
        >
          <template #caret>
            <div></div>
          </template>
          <span slot="noOptions"></span>
        </multiselect>
<!--        <img-->
<!--          src="../../assets/icons/search.svg"-->
<!--          width="16"-->
<!--          height="16"-->
<!--          alt="search"-->
<!--          class="search-icon"-->
<!--        />-->
        <IconWithTooltip :text="tooltip" class="ml-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';

export default {
  name: 'CITagField',
  components: { IconWithTooltip },
  props: {
    isColumn: {
      type: Boolean,
      default: false
    },
    value: Array,
    type: String,
    disabled: Boolean,
    label: { type: String, default: 'Tags' },
    tooltip: { type: String, default: 'Tags' },
    placeholder: { type: String, default: 'Select Tags' },
  },
  data() {
    return {
      selectedTagObjects: [],
      tagOptions: [],
      initTags: null,
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('tags', ['tags']),
  },
  methods: {
    ...mapActions('tags', ['handleCreateTags', 'handleGetTagsByQuery']),
    addTag(t) {
      const newTag = {
        name: t,
        key: t.toLowerCase().split(' ').join('_'),
        entityTypes: [this.type.replace('Create', '').replace('Request', '').replace('Update', '').replace('Simple', '')],
        metadata: {},
        description: 'Dynamically Created on the fly',
      };
      this.handleCreateTags({ createTagRequestArray: [newTag] });
      this.tagOptions.push(newTag);
      this.selectedTagObjects.push(newTag);
    },
    async initialize() {
      this.tagOptions = [];
      this.initTags = await this.handleGetTagsByQuery({
        queryRequest: {
          must: [
            {
              queryField: 'entityTypes',
              queryValues: [this.type
                .replace('Create', '')
                .replace('Request', '')
                .replace('Update', '')
                .replace('Entity', '')
                .replace('Simple', '')
              ]
            }
          ],
          sortBy: [
            {
              queryField: 'name',
              order: 'Asc',
            },
          ],
          limit: 20
        }
      });

      this.tagOptions = this.initTags;

      if (this.value.length > 0) {
        this.tagOptions = await this.handleGetTagsByQuery({
          queryRequest: {
            multiFields: [],
            must: [
              {
                queryField: 'entityTypes',
                queryValues: [this.type
                  .replace('Create', '')
                  .replace('Request', '')
                  .replace('Update', '')
                  .replace('Entity', '')
                  .replace('Simple', '')
                ]
              },
              {
                queryField: 'key',
                queryValues: this.value
              }
            ],
            sortBy: [
              {
                queryField: 'name',
                order: 'Asc'
              }
            ]
          }
        });

        this.selectedTagObjects = this.tagOptions.filter(tag => this.value.includes(tag.key));
      }
    },
    async onInput(val) {
      if (val.length >= 1) {
        const tags = await this.handleGetTagsByQuery({
          queryRequest: {
            multiFields: [
              {
                queryFields: ['name'],
                queryValue: val
              }
            ],
            must: [
              {
                queryField: 'entityTypes',
                queryValues: [this.type
                  .replace('Create', '')
                  .replace('Request', '')
                  .replace('Update', '')
                  .replace('Entity', '')
                  .replace('Simple', '')
                ]
              }
            ],
            sortBy: [
              {
                queryField: 'name',
                order: 'Asc',
              },
            ],
          }
        });

        this.tagOptions = tags;
      } else {
        this.tagOptions = this.initTags;
      }
    }
  },
  watch: {
    selectedTagObjects: {
      deep: true,
      handler(val) {
        this.$emit('input', val.map(tag => tag.key));
      },
    },
  },
};
</script>

<style lang="scss">
.multiselect__select {
  &:before {
    content: none;
  }
}


.tag-field-wrapper {

  .tag-field {
    width: inherit;
    position: relative;

    .search-icon {
      position: absolute;
      right: 20px;
      width: 36px !important;
    }

    .tags-multi-select {
      width: inherit !important;
      position: relative;
    }
  }
}

.tag-label-text {
  opacity: 0.5;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
</style>
