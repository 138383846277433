<template>
  <div class="rewards w-100">
    <TableRewards
      @createNewReward="createNewReward"
      @addFromTemplate="addFromTemplate"
      @updateRewardsData="updateRewardData"
      :rewardData="editRewardsData"
      v-on:updateRewards="updateRewards"
      @setPage="setPage"
      @showDeleteRewardModal="showDeleteRewardModal"
      v-if="isReady && currentPage.page === 'rewards'"
      :disabled="isFieldDisabled"
      :pageNumber="pageNumber"
      :isPageNumber="false"
      :loading="loading"
      :chosenRewards="selectedRewards"
      :isUpdate="true"
      :isFieldsFilter="false"
      :entityType="'Mission'"
    />
    <PreviewSpiner v-if="!isReady"/>
    <RewardTemplatesModal
      :isShowRewardTemplatesModal="isShowRewardTemplatesModal"
      @selectReward="selectReward"
      @closeModal="closeModal"
    />
    <CreateRewardModal
      :isShowRewardModal="isShowRewardModal"
      :selectedReward="selectedReward"
      @updateRewardData="createReward"
      @closeModal="closeModal"
    />
    <PreviewRewardModal
      :isShowPreviewModal="isShowPreviewModal"
      @closeModal="closeModal"
      :rewardData="selectedReward"
    />
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="messageGeneral"
      :title="delRewardsTitle"
      @doFunction="deleteReward"
      v-on:toggle-modal="closeModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TableRewards from './components/TableRewards';
import { pageConfig } from '@/config';
import { isCompFieldDisabled, isAchFieldDisabled } from '@/utils/validationUtils';
import { cloneDeep, delay, isEqual } from 'lodash';
import PreviewSpiner from '@/shared/UI/Spiner';
import PreviewRewardModal from '@/components/mission/PreviewRewardModal.vue';
import CreateRewardModal from '@/views/ziqni/instant-wins/CreateRewardModal.vue';
import RewardTemplatesModal from '@/views/ziqni/instant-wins/RewardTemplatesModal.vue';
import ClIcon from '@/shared/UI/ClIcon.vue';
import Modal from '@/shared/UI/Modal.vue';

export default {
  name: 'EditRewardsPopupView',
  components: {
    Modal,
    ClIcon,
    RewardTemplatesModal,
    CreateRewardModal,
    PreviewRewardModal,
    TableRewards,
    PreviewSpiner,
  },
  props: {
    texts: Object,
    entityId: {
      type: String,
      default: '',
    },
    entityType: {
      type: String,
      default: '',
    },
    isRewardRankField: {
      type: Boolean,
      default: true
    },
    pageNumber: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: 'Ach'
    },
    status: {
      type: String,
      default: 'Ready'
    },
    isDisplayHeader: {
      type: Boolean,
      default: true
    },
    notSavedRewardsData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      sortBy: pageConfig.sortBy,
      itemsPerPage: pageConfig.itemsPerPage,
      page: pageConfig.page,
      currentPage: { page: 'rewards', data: {} },
      editRewardsData: [],
      selectedRewards: [],
      selectedReward: {},
      loading: false,
      isReady: false,
      isRewardsDataSaved: true,
      templateRewardData: {},
      isShowRewardTemplatesModal: false,
      isShowRewardModal: false,
      isShowPreviewModal: false,
      isEditReward: false,
      deleteModal: false,
      activeToggleId: null,
      messageGeneral: this.texts.createPage.rewards.deleteMessage,
      delRewardsTitle: this.texts.createPage.rewards.deleteTitle,
    };
  },
  computed: {
    ...mapGetters('rewards', ['rewards']),
    isFieldDisabled() {
      if (this.pageType === 'Ach') {
        return isAchFieldDisabled(this.status);
      }
      return isCompFieldDisabled(this.status);
    }
  },
  created() {
    this.initialize();
  },
  destroyed() {
    if (!this.isRewardsDataSaved) {
      this.$emit('notSavedRewardsData', { editRewardsData: this.editRewardsData, startData: this.startData });
    }
  },
  methods: {
    ...mapActions('rewards', [
      'handleFields',
      'handleGetRewardsByQuery',
      'handleUpdateRewards',
      'handleCreateRewards',
      'handleDeleteRewardsByQuery'
    ]),
    async initialize() {
      if (Object.keys(this.notSavedRewardsData).length) {
        this.editRewardsData = cloneDeep(this.notSavedRewardsData.editRewardsData);
        this.selectedRewards = cloneDeep(this.notSavedRewardsData.editRewardsData);
        this.startData = cloneDeep(this.notSavedRewardsData.startData);
      } else {
        await this.handleGetRewardsByQuery(
          {
            queryRequest: {
              should: [{
                queryField: 'entityId',
                queryValues: [this.entityId]
              }],
              shouldMatch: 1
            }
          }
        ).then(data => {
          this.editRewardsData = cloneDeep(data);
          this.selectedRewards = cloneDeep(data);
          this.startData = cloneDeep(data);
        });
      }
      this.isReady = true;
      this.handleFields();
      if (!isEqual(this.editRewardsData, this.startData)) {
        this.isRewardsDataSaved = false;
        this.$emit('isRewardsDataSaved', false);
      }
    },
    createNewReward() {
      this.isRewardsDataSaved = true;
      this.currentPage.page = 'reward creation';
      this.isShowRewardModal = true;
    },
    async createReward(val) {
      const reward = val[0];
      this.templateRewardData = {};

      const baseData = {
        metadata: reward.metadata,
        entityType: this.entityType,
        entityId: this.entityId,
        name: reward.name,
        description: reward.description,
        rewardRank: reward.rewardRank,
        rewardValue: reward.rewardValue,
        icon: reward.icon,
        issueLimit: reward.issueLimit,
        delay: reward.delay,
        pointInTime: reward.pointInTime,
        period: reward.period,
        translations: [],
        rewardTypeId: reward.rewardTypeId,
      };

      if (!baseData.icon) delete baseData.icon;

      if (reward.isMemberAcknowledgmentRequired) {
        baseData.constraints = ['memberAcknowledgmentRequired'];
      }

      if (this.isEditReward && reward.id && !reward.id.includes('nxt_', 0)) {
        baseData.id = reward.id;
      }

      const handleResponse = async (promise) => {
        await promise;
        this.loading = true;
        delay(async () => {
          await this.initialize();
          this.loading = false;
          this.$emit('setRewardsDataSaved');
        }, 2500);
      };

      if (this.isEditReward && reward.id && !reward.id.includes('nxt_', 0)) {
        await handleResponse(this.handleUpdateRewards({ updateEntityRewardRequestArray: [baseData] }));
      } else {
        await handleResponse(this.handleCreateRewards({ createEntityRewardRequestArray: [baseData] }));
      }

      this.isShowRewardModal = false;
      this.currentPage.page = 'rewards';
      this.isEditReward = false;
    },
    addFromTemplate() {
      this.isRewardsDataSaved = true;
      this.isShowRewardTemplatesModal = true;
    },
    cancelReward() {
      this.editRewardsData = this.startData;
      this.templateRewardData = {};
      this.currentPage.page = 'rewards';
    },
    updateRewards(val) {
      this.editRewardsData = val;
      if (this.startData.length > val.length) {
        this.isRewardsDataSaved = false;
      }
    },
    setPage(val) {
      this.currentPage = val;
      if (val.page === 'preview reward') {
        this.showPreview(val.data);
      }
    },
    updateRewardsData(val) {
      this.templateRewardData = val[0];
    },
    updateRewardData(val, isEditReward) {
      this.isEditReward = isEditReward;
      if (this.selectedRewardIndex !== null) {
        this.$set(this.selectedRewards, this.selectedRewardIndex, val[0]);
      } else {
        this.selectedRewards.push(val[0]);
      }

      this.templateRewardData = val[0];
      this.selectedRewardIndex = null;
      this.isShowRewardModal = true;
      this.selectedReward = val[0];
    },
    addIcon(val) {
      this.$emit('addRewardIcon', val);
      this.currentPage = { page: 'reward creation', data: { id: val } };
    },
    closeModal() {
      this.isShowRewardModal = false;
      this.isShowRewardTemplatesModal = false;
      this.isShowPreviewModal = false;
      this.deleteModal = false;
      this.isEditReward = false;
      this.selectedReward = {};
      this.currentPage.page = 'rewards';
    },
    selectReward(reward) {
      this.isShowRewardTemplatesModal = false;
      this.selectedReward = reward;
      this.isShowRewardModal = true;
    },
    showPreview(item) {
      this.selectedReward = { ...item };
      this.isShowPreviewModal = true;
    },
    showDeleteRewardModal(item) {
      this.deleteModal = true;
      this.selectedReward = item;
    },
    deleteReward() {
      this.deleteModal = false;
      const rewardId = this.selectedReward.id;
      const queryRequest = {
        must: [
          {
            queryField: 'entityId',
            queryValues: [this.entityId]
          },
          {
            queryField: 'id',
            queryValues: [rewardId]
          }
        ]
      };

      this.handleDeleteRewardsByQuery({ queryRequest })
        .then(() => {
          this.loading = true;
          delay(async () => {
            await this.initialize();
            this.loading = false;
            this.$emit('setRewardsDataSaved');
          }, 2500);
        });
    },
    handleToggle(id) {
      this.activeToggleId = id;
    },
  },
};
</script>

<style lang="scss">
.rewards {
  height: 100%;
}
</style>
