<template>
  <div class="achievement-detail">
    <div class="form-content content">
      <div class="d-flex justify-content-end">
        <ClNavButton
          v-if="isMissionPreview"
          class="mr-2"
          :label="$t('buttons.goBack')"
          @handler="goBack"
        />
        <ActionsForPreview
          :loading="loading"
          :isExportEntrantsEnabled="true"
          @edit="editAchievement"
          @delete="openModal"
          @exportEntrants="openExportModal"
        />
      </div>

      <div v-if="ready">
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card" v-if="ready">
              <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ isMissionPreview ? texts.previewPage.missionStepPreviewTitle : texts.previewPage.title }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="basicIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="basicIsCollapsed" :duration="400">
                <CCardBody>
                  <ZqFieldsView :texts="texts.previewPage" :formData="formData" :modelFields="modelFields" />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <MissionsGraph
          v-if="formData.constraints.includes('mission') && graphData"
          :graphData="graphData"
          :missionsData="missionsData"
        />
        <ViewScheduling
          v-if="schedulingData"
          :schedulingData="schedulingData"
        />
        <ViewEntrants
          v-if="isViewEntrants"
          :dependantOnData="formData.memberTagsFilter"
          :constraints="formData.constraints"
        />
        <ViewDependantOn
          v-if="formData.dependantOn && Object.keys(formData.dependantOn).length"
          :dependantOnData="formData.dependantOn"
        />
        <ViewRewards :entityId="entityId" v-if="rewardModels.includes(model)"/>
        <ViewPointsStrategy :score-points-data="formData.strategies" />
        <ViewRules
          v-if="ruleModels.includes(model)"
          :entityId="entityId"
          context="achievement"
        />
        <Products
          v-if="Object.keys(productsData).length"
          :productsData="productsData"
          :isPreview="true"
        />
        <ViewTranslations
          v-if="modelFields.baseFields.translatableFields.length > 0"
          :entityData="formData"
          :entityId="formData.id"
          :translatableFields="modelFields.baseFields.translatableFields"
        />
      </div>
      <PreviewSpiner v-else />
    </div>
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="texts.deleteMessage"
      :title="texts.deleteTitle"
      @doFunction="deleteAchievement"
      v-on:toggle-modal="deleteModal = false"
    />
    <Modal
      :modalShow="exportModal"
      :messageGeneral="exportMessage"
      :title="texts.exportTitle"
      @doFunction="exportEntrants"
      v-on:toggle-modal="exportModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ActionsForPreview from '@/shared/components/ActionsForPreview';
import ZqFieldsView from '@/shared/components/ZqFieldsView';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import { pageConfig } from '@/config';
import PreviewSpiner from '@/shared/UI/Spiner';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import Modal from '@/shared/UI/Modal';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import fields from '@/generated/ziqni/store/modules/achievements/fields';
import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations';
import ViewRules from '@/shared/components/supportModels/rules/ViewRules';
import ViewDependantOn from '@/shared/components/supportModels/dependantOn/ViewDependantOn';
import ViewEntrants from '@/shared/components/supportModels/entrants/ViewEntrants';
import ViewScheduling from '@/shared/components/supportModels/scheduling/ViewScheduling';
import ViewRewards from '@/shared/components/supportModels/rewards/ViewRewards';
import ViewPointsStrategy from '@/shared/components/supportModels/achievements/ViewPointsStrategy';
import Products from '@/shared/components/steps/components/Products';
import axios from 'axios';
import MissionsGraph from '@/shared/components/supportModels/achievements/MissionsGraph.vue';
import ClNavButton from '@/shared/components/formComponents/ClNavButton.vue';

export default {
    name: 'AchievementDetails',
    components: {
      ClNavButton,
      MissionsGraph,
      Products,
      ViewPointsStrategy,
      PreviewSpiner,
      ActionsForPreview,
      ZqFieldsView,
      Modal,
      ClCardArrow,
      ViewTranslations,
      ViewRules,
      ViewDependantOn,
      ViewScheduling,
      ViewRewards,
      ViewEntrants
    },
    props: {
      isMissionPreview: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        model: 'achievement',
        entityId: this.$route.params.id,
        formData: {
          id: '',
          spaceName: '',
          created: '',
          customFields: {},
          tags: [],
          metadata: {},
          name: '',
          description: '',
          termsAndConditions: '',
          icon: '',
          scheduling: '',
          maxNumberOfIssues: '',
          status: '',
          statusCode: '',
          constraints: [],
          achievementDependencies: '',
          memberTagsFilter: '',
          productTagsFilter: '',
          strategies: {}
        },
        texts: {
          ...achievementsTexts,
        },
        basicIsCollapsed: true,
        inboxIsCollapsed: false,
        deleteModal: false,
        exportModal: false,
        exportMessage: '',
        itemsPerPage: pageConfig.itemsPerPage,
        query: {},
        sortBy: pageConfig.sortBy,
        page: pageConfig.page,
        modelFields : {
          ...fields,
        },
        ready: false,
        schedulingData: null,
        rewardModels: ['achievement'],
        ruleModels: ['achievement'],
        productsData: {
          currentTypes: {},
          isAllProductsInclude: true,
          selectedData: [],
          formData: {
            shouldMatchAtLeast: 1,
            dependantOn: {
              must: [],
              mustNot: [],
              should: [],
            }
          },
        },
        graphData: null,
        missionsData: null
      };
    },
    computed: {
      ...mapGetters('achievements', [
        'success',
        'message',
        'achievements',
        'loading',
        'achievement'
      ]),
      ...mapGetters('theme', ['theme']),
    },
    created() {
      this.initialize();
    },
    methods: {
      ...mapActions('achievements', [
        'handleGetAchievements_item',
        'handleDeleteAchievements',
        'handleGetAchievementsByQuery'
      ]),
      ...mapActions('entrants', ['handleExportEntrantsByQuery']),
      ...mapActions('tags', ['handleGetTagsByQuery']),
      async initialize() {
        await this.handleGetAchievements_item([this.$route.params.id],1,0).then((data) => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name,
          });

          this.$emit('setAchievementStatus', data[0].status);

          if (data[0].scheduling) {
            this.schedulingData = data[0].scheduling;
          }

          this.formData.dependantOn = data[0].achievementDependencies;

          if (data[0].products && data[0].products.length) {
            this.productsData.selectedData = data[0].products;
            this.productsData.isAllProductsInclude = false;
          }

          if (data[0].productTagsFilter && Object.keys(data[0].productTagsFilter).length) {
            this.productsData.formData.shouldMatchAtLeast = data[0].productTagsFilter.shouldMatchAtLeast;
            this.productsData.formData.dependantOn.must = data[0].productTagsFilter.must ?? [];
            this.productsData.formData.dependantOn.should = data[0].productTagsFilter.should ?? [];
            this.productsData.formData.dependantOn.mustNot = data[0].productTagsFilter.mustNot ?? [];

            let tags = [
              ...this.productsData.formData.dependantOn.must,
              ...this.productsData.formData.dependantOn.should,
              ...this.productsData.formData.dependantOn.mustNot,
            ]

            this.handleGetTagsByQuery({
              queryRequest: {
                must: [
                  {
                    queryField: 'key',
                    queryValues: tags
                  }
                ]
              }
            }).then(data => this.productsData.selectedData = data)

            this.productsData.isAllProductsInclude = false;
          }

          this.ready = true;
        });

        const isMission = this.formData.constraints.includes('mission');

        if (isMission) {
          const token = localStorage.getItem('vue-token')

          const path = 'https://api.ziqni.com/graphs'

          const entityGraphRequest = {
            ids: [this.$route.params.id],
            constraints: [],
            languageKey: '',
            includes: [],
            entityType: 'Achievement'
          }

          const { data } = await axios.post(path, entityGraphRequest, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });

          const nodesIds = data.data.nodes.map(node => node.entityId);

          const subarray = [];
          for (let i = 0; i < Math.ceil(nodesIds.length / 20); i++) {
            subarray[i] = nodesIds.slice((i * 20), (i * 20) + 20);
          }

          const achievementsSubarray = [];

          for (let i = 0; i < subarray.length; i++) {
            const achievements = await this.handleGetAchievementsByQuery({
              queryRequest: {
                must: [
                  {
                    queryField: 'id',
                    queryValues: subarray[i]
                  }
                ],
                skip: 0,
                limit: 20
              }
            });
            achievementsSubarray.push(achievements);
          }

          this.missionsData = achievementsSubarray.flat();

          this.graphData = data.data;
        }
      },
      async goBack() {
        this.$router.go(-1);
      },
      getAchievementOrder(nodes, edges) {
        const edgesResult = {};
        const nodesResult = {};

        for (const edge of edges) {
          if (edge.headEntityId !== null) {
            let color;
            switch (edge.graphEdgeType) {
              case 'MUST': {
                color = '#6FCF97';
                break;
              }
              case 'SHOULD': {
                color = 'rgb(238, 187, 0)';
                break;
              }
              case 'MUST-NOT': {
                color = '#EB5757';
                break;
              }
            }
            edgesResult['edge' + edge.ordering] = {
              source: edge.headEntityId,
              target: edge.tailEntityId,
              label: edge.graphEdgeType,
              color: color
            };
          }
        }

        for (const node of nodes) {
          nodesResult[node.entityId] = { name: node.name, id: node.entityId };
        }

        return { edgesResult, nodesResult };
      },
      isViewEntrants() {
        return this.formData.memberTagsFilter
            && Object.values(this.formData.memberTagsFilter).flat()
            .filter(e => typeof e === 'string' && e !== '').length
      },
      editAchievement() {
        this.$router.push({
          name: 'EditAchievement',
          params: {
            id: this.formData.id,
            name: this.formData.name,
          },
        });
      },
      deleteAchievement() {
        this.deleteModal = false;
        this.handleDeleteAchievements({
          idArray: [this.entityId],
          queryData: this.query,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }).then(() => this.$router.push({ name: 'Achievements' }).catch((e) => {
            console.log(e.message);
           }));
      },
      async exportEntrants() {
        this.exportModal = false;
        const exportData = await this.handleExportEntrantsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'participationId',
                queryValues: [this.$route.params.id]
              }
            ]
          }
        });

        if (exportData.numberOfRecords > 0) {
          const token = localStorage.getItem('vue-token');
          const csv = await axios.get(exportData.downloadUrl, {
            headers: {"Authorization" : `Bearer ${token}`},
            responseType: 'text/csv'
          });

          const blob = new Blob([csv.data], {type : 'text/csv'});
          const link = document.createElement('a');

          link.href = URL.createObjectURL(blob);
          link.download = this.formData.name + '_entrants';
          link.click();
          URL.revokeObjectURL(link.href);
        }
      },
      openModal() {
        this.deleteModal = true;
      },
      async openExportModal() {
        const exportData = await this.handleExportEntrantsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'participationId',
                queryValues: [this.$route.params.id]
              }
            ]
          }
        });

        this.exportMessage = `You are about to download ${exportData.numberOfRecords} rows of data`

        this.exportModal = true;
      },
    },
    watch: {
      achievement: {
        deep: true,
        handler: function (val) {
          this.formData = val;
        },
      }
    },
  };
</script>
<style lang="scss">
  achievement-detail {
    height: 100%;
    flex: 2;
    .content {
      border-top: 1px solid var(--zq-content-border-color);
    }
    .form-content {
      height: calc(100% - 50px);
      background: var(--zq-sub-bg);
    }
    .lang-custom-button {
      width: 100%;
    }
  }

  .detail-label {
    @media (min-width: 420px) {
      max-width: 220px;
    }
  }

  .overview-label {
    font-style: italic;
    color: darkgray;
  }
</style>
