const Missions = () => import('@/views/ziqni/missions');
const PreviewMission = () => import('@/views/ziqni/achievements/PreviewMission');
const EditMission = () => import('@/views/ziqni/achievements/EditMission');
const CreateMission = () => import('@/views/ziqni/missions/CreateMission');
// src/views/ziqni/missions/CreateMission.vue

export default {
  path: 'missions',
  component: {
    render(c) {
      return c('router-view');
    },
  },
  children: [
    {
      path: '',
      name: 'Missions',
      component: Missions,
    },
    {
      path: 'create',
      name: 'CreateMission',
      component: CreateMission,
    },
    {
      path: 'detail/:id',
      name: 'PreviewMission',
      component: PreviewMission,
    },
    {
      path: 'edit-mission/:id',
      name: 'EditMission',
      component: EditMission,
    },
  ],
}
